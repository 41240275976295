import React, { Component } from "react";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";

class flightDetail extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navigation />
        <div className="__page__wrap spacing_l">
          <div className="container container-lg container-xl container-xxl">
            <div className="__flight__detail__timeline">
              <h4 className="__timeline__title">FLIGHT DETAILS</h4>

              <ul className="__fd__timeline__heading">
                <li>
                  <span>Depart</span>
                </li>
                <li>Thu, Jul 20</li>
                <li>Dhaka - New York</li>
                <li>
                  <img src="/assets/images/clock-small.svg" alt="" />
                  22h 45m
                </li>
              </ul>

              <ul className="__fd__timeline">
                <li>
                  <span>DAC Dhaka Hazrat Shahjalal International Airport</span>
                  <div className="__fd__left__time">
                    <span className="__left__time__l">01:40</span>
                    <span className="__left__time__s">4h 50m</span>
                  </div>
                  <div className="__r__p__title">
                    <img src="/assets/images/emi.svg" alt="" />
                    <div>
                      <h6>Emirates EK585</h6>
                      <p>
                        <span>Boeing 777-300ER</span> <span>Economy class</span>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <span>DXB Dubai International Airport T3</span>
                  <div className="__fd__left__time">
                    <span className="__left__time__l">04:30</span>
                  </div>
                </li>
                <li>
                  <span>
                    <div className="__fd__middle">
                      <h6>Transfer in Dubai</h6>
                      <ul>
                        <li>A transit visa may be required.</li>
                        <li>
                          For flight transfers in Dubai, baggage can be check
                          through.
                        </li>
                      </ul>
                    </div>
                  </span>
                  <div className="__fd__left__time">
                    <span className="__left__time__l">4h 50m</span>
                  </div>
                </li>
                <li>
                  <span>DXB Dubai International Airport T3</span>
                  <div className="__fd__left__time">
                    <span className="__left__time__l">8:30</span>
                    <span className="__left__time__s">13h 55m</span>
                  </div>
                  <div className="__r__p__title">
                    <img src="/assets/images/emi.svg" alt="" />
                    <div>
                      <h6>Emirates EK585</h6>
                      <p>
                        <span>Boeing 777-300ER</span> <span>Economy class</span>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <span>DAC Dhaka Hazrat Shahjalal International Airport</span>
                  <div className="__fd__left__time">
                    <span className="__left__time__l">14:25</span>
                  </div>
                </li>
              </ul>

              <ul className="__fd__timeline__heading">
                <li>
                  <span>Return</span>
                </li>
                <li>Thu, Jul 20</li>
                <li>Dhaka - New York</li>
                <li>
                  <img src="/assets/images/clock-small.svg" alt="" />
                  22h 45m
                </li>
              </ul>
              <ul className="__fd__timeline">
                <li>
                  <span>DAC Dhaka Hazrat Shahjalal International Airport</span>
                  <div className="__fd__left__time">
                    <span className="__left__time__l">01:40</span>
                    <span className="__left__time__s">4h 50m</span>
                  </div>
                  <div className="__r__p__title">
                    <img src="/assets/images/emi.svg" alt="" />
                    <div>
                      <h6>Emirates EK585</h6>
                      <p>
                        <span>Boeing 777-300ER</span> <span>Economy class</span>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <span>DXB Dubai International Airport T3</span>
                  <div className="__fd__left__time">
                    <span className="__left__time__l">04:30</span>
                  </div>
                </li>
                <li>
                  <span>
                    <div className="__fd__middle">
                      <h6>Transfer in Dubai</h6>
                      <ul>
                        <li>A transit visa may be required.</li>
                        <li>
                          For flight transfers in Dubai, baggage can be check
                          through.
                        </li>
                      </ul>
                    </div>
                  </span>
                  <div className="__fd__left__time">
                    <span className="__left__time__l">4h 50m</span>
                  </div>
                </li>
                <li>
                  <span>DXB Dubai International Airport T3</span>
                  <div className="__fd__left__time">
                    <span className="__left__time__l">8:30</span>
                    <span className="__left__time__s">13h 55m</span>
                  </div>
                  <div className="__r__p__title">
                    <img src="/assets/images/emi.svg" alt="" />
                    <div>
                      <h6>Emirates EK585</h6>
                      <p>
                        <span>Boeing 777-300ER</span> <span>Economy class</span>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <span>DAC Dhaka Hazrat Shahjalal International Airport</span>
                  <div className="__fd__left__time">
                    <span className="__left__time__l">14:25</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default flightDetail;
