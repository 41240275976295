import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import IndexPage from "./pages";
import ComponentPage from "./pages/componentPage";

import HomePage from "./pages/home/homePage";
import BlogPage from "./pages/blog/blogPage";
import MyAccount from "./pages/my-account/myAccount";
import SignUp from "./pages/signup/signup";
import Booking from "./pages/booking/booking";
import ManageBooking from "./pages/manage-booking/manageBooking";
import Feedback from "./pages/feedback/feedback-landing";
import ModalComponent from "./pages/modalComponent";
import FlightSearchLanding from "./pages/flight-search/flight-search-landing";
import flightSearchDetail from "./pages/flight-search/flightSearchDetail";
import Login from "./pages/login/login";
import flightDetail from "./pages/flight-search/flightDetail";
import CustomBlogPage from "./pages/blog/CustomBlogPage";
import CustomeSignUp from "./pages/signup/CustomeSignUp";
import CustomeLogin from "./pages/login/CustomeLogin";
import CommentRating from "./pages/blog/CommentRating";
import PersonalDetails from "./pages/my-account/PersonalDetails";
import PrivateRoute from "./PrivateRouting/PrivateRoute";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound404 from "./pages/NotFound404";
import SchemaMarkup from "./SchemaMarkup";

class App extends React.Component {
  render() {
    return (
      <>
      {/* <SchemaMarkup /> */}
      <div className="App">
        
        <Router>
          <div>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/home" component={HomePage} ></Route>
              <Route path="/blog" component={CustomBlogPage} exact></Route>
              <PrivateRoute path="/addcomment">
                <CommentRating />
              </PrivateRoute>
              <PrivateRoute path="/my-account" >
                <MyAccount />
              </PrivateRoute>
              <Route path="/personal-details" component={PersonalDetails}  />
              <Route path="/signup" component={CustomeSignUp} ></Route>
              <Route path="/login" component={CustomeLogin} ></Route>
              <Route path="/forgot-password" component={ForgotPassword} ></Route>
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/terms-of-use" component={TermsOfUse} />
              <Route path="/:title" component={CustomBlogPage} exact></Route>
              <Route component={NotFound404}/>
              <Redirect from="*" to="/404" />
            </Switch>
          </div>
        </Router>
      </div>
      </>
    );
  }
}

export default App;
