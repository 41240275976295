import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }}
      onClick={onClick}
    >
      <img src="/assets/images/next-a.svg" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }}
      onClick={onClick}
    >
      <img src="/assets/images/after-a.svg" />
    </div>
  );
}

class Column3SliderSpecial extends Component {
  state = {};
  render() {
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Slider {...settings}>
        <div>
          <div className="__other__card">
            <div className="__other__card__wrap">
              <div className="__card__img">
                <img src="/assets/images/rectangle.png" />
              </div>
              <div className="__card__info">
                <h4 className="__info__title">
                  Golden Temple Tour Packages, Making Your HolidaysSpecial?
                </h4>
                <p>
                  Amritsar or the holy pool of nectar is a significant spiritual
                  destination for the Sikh community.
                </p>
              </div>
            </div>
            <div className="__cupon__code">
              <img src="/assets/images/c.svg" />
              <span>GOFLYO410</span>
            </div>
          </div>
        </div>
        <div>
          <div className="__other__card">
            <div className="__other__card__wrap">
              <div className="__card__img">
                <img src="/assets/images/rectangle.png" />
              </div>
              <div className="__card__info">
                <h4 className="__info__title">
                  Golden Temple Tour Packages, Making Your HolidaysSpecial?
                </h4>
                <p>
                  Amritsar or the holy pool of nectar is a significant spiritual
                  destination for the Sikh community.
                </p>
              </div>
            </div>
            <div className="__cupon__code">
              <img src="/assets/images/c.svg" />
              <span>GOFLYO410</span>
            </div>
          </div>
        </div>
        <div>
          <div className="__other__card">
            <div className="__other__card__wrap">
              <div className="__card__img">
                <img src="/assets/images/rectangle.png" />
              </div>
              <div className="__card__info">
                <h4 className="__info__title">
                  Golden Temple Tour Packages, Making Your HolidaysSpecial?
                </h4>
                <p>
                  Amritsar or the holy pool of nectar is a significant spiritual
                  destination for the Sikh community.
                </p>
              </div>
            </div>
            <div className="__cupon__code">
              <img src="/assets/images/c.svg" />
              <span>GOFLYO410</span>
            </div>
          </div>
        </div>
        <div>
          <div className="__other__card">
            <div className="__other__card__wrap">
              <div className="__card__img">
                <img src="/assets/images/rectangle.png" />
              </div>
              <div className="__card__info">
                <h4 className="__info__title">
                  Golden Temple Tour Packages, Making Your HolidaysSpecial?
                </h4>
                <p>
                  Amritsar or the holy pool of nectar is a significant spiritual
                  destination for the Sikh community.
                </p>
              </div>
            </div>
            <div className="__cupon__code">
              <img src="/assets/images/c.svg" />
              <span>GOFLYO410</span>
            </div>
          </div>
        </div>
        <div>
          <div className="__other__card">
            <div className="__other__card__wrap">
              <div className="__card__img">
                <img src="/assets/images/rectangle.png" />
              </div>
              <div className="__card__info">
                <h4 className="__info__title">
                  Golden Temple Tour Packages, Making Your HolidaysSpecial?
                </h4>
                <p>
                  Amritsar or the holy pool of nectar is a significant spiritual
                  destination for the Sikh community.
                </p>
              </div>
            </div>
            <div className="__cupon__code">
              <img src="/assets/images/c.svg" />
              <span>GOFLYO410</span>
            </div>
          </div>
        </div>
        <div>
          <div className="__other__card">
            <div className="__other__card__wrap">
              <div className="__card__img">
                <img src="/assets/images/rectangle.png" />
              </div>
              <div className="__card__info">
                <h4 className="__info__title">
                  Golden Temple Tour Packages, Making Your HolidaysSpecial?
                </h4>
                <p>
                  Amritsar or the holy pool of nectar is a significant spiritual
                  destination for the Sikh community.
                </p>
              </div>
            </div>
            <div className="__cupon__code">
              <img src="/assets/images/c.svg" />
              <span>GOFLYO410</span>
            </div>
          </div>
        </div>
      </Slider>
    );
  }
}

export default Column3SliderSpecial;
