import React, { Component } from "react";

class Login extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="__login">
          <div className="__login__content">
            <div className="container container-xl container-xxl">
              <div className="row">
                <div className="col-md-5 __order__2">
                  <div className="__login__inputs">
                    <div className="__social__login">
                      <button className="__google">GOOGLE</button>
                      <button className="__facebook">FACEBOOK</button>
                    </div>
                    <div className="inputs">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="email">User ID</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Supportbd"
                        />
                      </div>
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="support@buyairticket.com"
                        />
                      </div>
                      <p className="__f__password">
                        <a href="">Can't sign in? Forgot Password?</a>
                      </p>
                      <div className="CTA__wrap">
                        <button className="__submit">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 __order__1">
                  <div className="__login__img">
                    <img src="/assets/images/login.PNG" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
