import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }}
      onClick={onClick}
    >
      <img src="/assets/images/next-a.svg" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }}
      onClick={onClick}
    >
      <img src="/assets/images/after-a.svg" />
    </div>
  );
}

class Column3SliderExplore extends Component {
  state = {};
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      rows: 2,
      slidesPerRow: 3,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            slidesPerRow: 1,
          },
        },
      ],
    };
    return (
      <Slider {...settings}>
        <div>
          <div className="__img__card __ontop__bottomText">
            <div className="__card__img">
              <a href="https://buyairticket.com/static.action?page=trip/useful-travelling-tips-shared-by-the-best-travel-bloggers" target="_blank">
                <img src="https://buyairticket.com/ibe/images/packages/tips-thumb.jpg" />
              </a>
            </div>
            <div className="__info__ontop__wrap">
              <a href="https://buyairticket.com/static.action?page=trip/useful-travelling-tips-shared-by-the-best-travel-bloggers" target="_blank"><p className="__info__ontop">Useful Travelling Tips Shared By The Best Travel Bloggers</p></a>
            </div>
          </div>
        </div>
        <div>
          <div className="__img__card __ontop__bottomText">
            <div className="__card__img">
              <a href="https://buyairticket.com/static.action?page=trip/top-8-beach-destinations-to-visit-in-india" target="_blank">
                <img src="https://buyairticket.com/ibe/images/packages/india-thumb.jpg" />
              </a>
            </div>
            <div className="__info__ontop__wrap">
              <a href="https://buyairticket.com/static.action?page=trip/top-8-beach-destinations-to-visit-in-india" target="_blank"><p className="__info__ontop">Top 8 Beach Destinations To Visit In India</p></a>
            </div>
          </div>
        </div>
        <div>
          <div className="__img__card __ontop__bottomText">
          <div className="__card__img">
            <a href="https://buyairticket.com/static.action?page=trip/top-3-honeymoon-destinations-when-you-are-on-a-budget" target="_blank">
              <img src="https://buyairticket.com/ibe/images/packages/honey-thumb.jpg" />
            </a>
            </div>
            <div className="__info__ontop__wrap">
              <a href="https://buyairticket.com/static.action?page=trip/top-3-honeymoon-destinations-when-you-are-on-a-budget" target="_blank"><p className="__info__ontop">Top 3 Honeymoon Destinations When You Are On A Budget</p></a>
            </div>
          </div>
        </div>
        <div>
          <div className="__img__card __ontop__bottomText">
            <div className="__card__img">
              <a href="https://buyairticket.com/static.action?page=trip/the-impact-of-coronavirus-on-business-travel" target="_blank">
                <img src="https://buyairticket.com/ibe/images/packages/corona-business-thumb.jpg" />
              </a>
            </div>
            <div className="__info__ontop__wrap">
              <a href="https://buyairticket.com/static.action?page=trip/the-impact-of-coronavirus-on-business-travel" target="_blank"><p className="__info__ontop">The Impact Of Coronavirus On Business Travel</p></a>
            </div>
          </div>
        </div>
        <div>
          <div className="__img__card __ontop__bottomText">
            <div className="__card__img">
              <a href="https://buyairticket.com/static.action?page=trip/the-5-best-family-vacation-destinations-in-the-united-states" target="_blank">
                <img src="https://buyairticket.com/ibe/images/packages/family-usa-thumb.jpg" />
              </a>
            </div>
            <div className="__info__ontop__wrap">
              <a href="https://buyairticket.com/static.action?page=trip/the-5-best-family-vacation-destinations-in-the-united-states" target="_blank"><p className="__info__ontop"> The 5 Best Family Vacation Destinations In The United States</p></a>
            </div>
          </div>
        </div>
        <div>
          <div className="__img__card __ontop__bottomText">
          <div className="__card__img">
            <a href="https://buyairticket.com/static.action?page=trip/planning-a-business-trip-on-a-budget-during-covid" target="_blank">
              <img src="https://buyairticket.com/ibe/images/packages/covid-trip-thumb.jpg" />
            </a>
            </div>
            <div className="__info__ontop__wrap">
              <a href="https://buyairticket.com/static.action?page=trip/planning-a-business-trip-on-a-budget-during-covid" target="_blank"><p className="__info__ontop">Planning A Business Trip On A Budget During Covid</p></a>
            </div>
          </div>
        </div>
      </Slider>
    );
  }
}

export default Column3SliderExplore;
