import React from 'react'
import {Link} from 'react-router-dom'
const NotFound404 = () => {
    return (
        <div>
            <h1>404 not found!</h1>
            <hr />
            <Link to="/">
                Go Home
            </Link>
        </div>    
    )
}

export default NotFound404
