import React from 'react'
import Footer from '../components/footer'
import Navigation from '../components/navigation'

const PrivacyPolicy = () => {
    const styleOfPrivacy = {
        fontSize: "13px",
        fontFamily: '"Times New Roman","serif"',
        marginBottom: "25px"
    }
    const styleOfPrivacyTitle= {
        fontSize: "21px",
        fontFamily: '"Helvetica","sans-serif"',
    }
    return (
        <section className="container">
            <Navigation />
            <div>
                <h4 style={styleOfPrivacyTitle}>Privacy Policy</h4>
                <p style={styleOfPrivacy}>
                    We respect your privacy and recognize the need to protect the personally identifiable information (any information by which you can be identified, such as name, address, and telephone number) you share with us. We would like to assure you that we follow appropriate standards when it comes to protecting your privacy on our app and website.

                <br />
                <br />
                Some of our web pages use "cookies" so that we can better serve you with customized information when you return to our site. Cookies are identifiers which websites send to the browser on your computer to facilitate your next visit to our site. You can set your browser to notify you when you are sent a cookie, giving you the option to decide whether or not to accept it. The information we collect and analyze is used to improve our service to you.
                <br />
                <br />
                BuyAirTicket.com collects the following personal sensitive information from you while transacting through BuyAirTicket.com, this includes your Name and Sex, Phone Number, Billing Address, and Date of birth in case of a child.
                <br />
                <br />
                BuyAirTicket.com does not sell or trade upon any of the above foregoing information without the consent of the user or customer. The foregoing information collected from the users/customers/travellers is put to the following use:
                <br />
                <br />
                Customer name, address, phone number, traveller's name and age are shared with applicable service providers like the airlines, hotels, etc., for the purpose of reservation and booking the services for the customer/traveller.
                <br />
                <br />
                Information like Phone number, email address and billing address may be used for promotional purposes, unless the customer/user has unsubscribed from such services. If you choose not to share this information, you can still visit the BuyAirTicket.com website, but you may be unable to access certain options, offers and services.
                <br />
                <br />
                BuyAirTicket.com takes appropriate steps to protect the information you share with us. We have implemented technology and security features and strict policy guidelines to safeguard the privacy of your personally identifiable information from unauthorized access and improper use or disclosure. Please contact us on to obtain names and addresses of the specific entities that shall have access to your personal information in a given transaction.
                <br />
                <br />
                BuyAirTicket.com will continue to enhance its security procedures as new technology becomes available, and ensures that its security procedures are compliant with current applicable regulations.
                <br />
                <br />
                If our privacy policy changes in the future, it will be posted here and a new effective date will be shown. You should access our privacy policy regularly to ensure you understand our current policies.
                <br />
                <br />
                *All prices and offers are subject to change with availability.</p>
                <h4 style={styleOfPrivacyTitle}>Cookie Policy</h4>
                <p style={styleOfPrivacy}>Buy Air Ticket website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our website. Privacy laws in the European Economic Area (EEA), including the General Data Protection Regulation, or GDPR, require websites to obtain consent for using or storing some cookies (or similar technologies) on your computers or mobile devices. By indicating your consent to the use of cookies, you agree that we can place cookies and other similar technology on your device, including a mobile device.

                This cookie policy provides you with clear and comprehensive information about the cookies we use and the purposes for using those cookies. To review the general privacy policies that apply to users of buyairticket.com. Please read our Privacy Policy.</p>
                <h4 style={styleOfPrivacyTitle}>Cookie Audit</h4>
                <p style={styleOfPrivacy}>We will perform a cookie audit on a regular basis, which will identify all cookies that our website installs, who owns those cookies, and their purpose, whether or not they fall within the definition “Strictly Necessary” for the service requested by the user, how intrusive the cookies are, and what steps should be taken to either remove the intrusive cookie or obtain the user’s informed consent.

                To find out about specific cookies we use on this website, please see below for details. However, please note that not all cookies may be used in all jurisdictions or websites.

                The cookies used on our website are categorized as follows:
                <ul>
                    <li>1. Strictly Necessary</li>
                    <li>2. Preferences</li>
                    <li>3. Statistics</li>
                    <li>4. Marketing</li>
                    <li>5. Third Party</li>
                </ul>
                <br />
                    This cookie policy provides you with clear and comprehensive information about the cookies we use and the purposes for using those cookies. To review the general privacy policies that apply to users of buyairticket.com. Please read our Privacy Policy.
                </p>
                <h4 style={styleOfPrivacyTitle}>Preference Cookies:</h4>
                <p style={styleOfPrivacy}>“Preference” cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in. We use cookies that collect information about your choices and preferences. These cookies allow us to remember language or other local settings and customize the sites accordingly. We may store settings and preferences of our logged in users.</p>
                <h4 style={styleOfPrivacyTitle}>Marketing Cookies</h4>
                <p style={styleOfPrivacy}>“Marketing” cookies are used to track visitors across websites. The intention is to display advertisements that are relevant and engaging for the individual user and thereby more valuable for publishers and third-party advertisers. We use cookies to deliver many types of digital marketing. They store user data and behavior information, which allows advertising agencies to target audience groups according to different variables, like age, gender, location, interests.
                </p>
                <h4 style={styleOfPrivacyTitle}>Third Party Cookies</h4>
                <p style={styleOfPrivacy}>“Third Party” cookies are used within our services which are set by other organizations. We use cookies served by third parties in order to improve our website; personalize your experience; and understand how our website is used.

                    While accessing our website, some cookies may be sent from other websites like Facebook, Twitter, etc. These Third-Party cookies are responsible for having their own cookie and privacy policies. They are not under our control and are not monitored or reviewed by us.

                    Before using Third Party cookies, please read and understand their cookie and privacy policies to ensure you agree to their terms.</p>
            </div>
            <Footer />
        </section>
    )
}

export default PrivacyPolicy
