import React, { useRef, useState } from 'react'
import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { parsePhoneNumberFromString } from 'libphonenumber-js'

const schema = yup.object().shape({
    firstName: yup
        .string()
        .matches(/^([^0-9]*)$/, "First name should not contain numbers")
        .required("First name is a required field"),
    lastName: yup
        .string()
        .matches(/^([^0-9]*)$/, "Last name should not contain numbers")
        .required("Last name is a required field"),
    email: yup
        .string()
        .email("Email should have correct format")
        .required("Email is a require field"),
    password: yup
        .string()
        .required('Please Enter your password')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    confirm_password: yup
        .string()
        .required('Confirm Password is required')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .oneOf([yup.ref('password'), null], 'Confirm Password does not match'),
      acceptTerms: yup.bool().oneOf([true], 'Accept Terms is required')
});

const normalizePhoneNumber =(value)=>{
  const phone  = parsePhoneNumberFromString(value)
  if(!phone){
      return value
  }
  return(
      phone.formatInternational()
  )
}

const CustomeSignUp = () => {
  const { enqueueSnackbar } = useSnackbar();
    const baseUrl = `https://admin.buyairticket.com.bd`;
    const history = useHistory();
    const { register, formState: { errors }, handleSubmit } = useForm({
        mode: "onBlur",
        resolver: yupResolver(schema),
    });
    const onSubmit = data => {
        const {title,firstName,lastName,email,phone,password,Status} = data;
        // const status = parseInt(Status);
        const status = 1;
        const userData = {
          title,
          firstName, 
          lastName,
          email,
          phone,
          password,
          status
        };
        sessionStorage.setItem("user", JSON.stringify(userData));
        console.log(userData);
        fetch(`${baseUrl}/api/user/usercreate`,{
          method:'POST',
          headers: { 
            "Content-Type": "application/json",
            'Accept': 'application/json, text/plain, */*',
          },
          body: JSON.stringify(userData)
        })
        .then(res => res.json())
        .then(success =>{
          if(success){
            enqueueSnackbar("Account Created Successfully", {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            });
            history.push("/login")
          }
          // else{
          //   alert("email already taken by another user!");
          // }
        })
    };
    return (
        <>
        <Navigation />
        <div className="page__wrap spacing_l">
          <div className="container container-lg container-xl container-xxl">
            <div className="row">
              <div className="col-12 col-md-10 col-lg-9 col-xl-9 col-xxl-9 mx-auto">
                <div className="__request__box__wrap">
                  <div className="row">
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <div className="__request__box__leftImg">
                        <img src="./assets/images/signup-left.png" />
                      </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                      <div className="__request__box">
                        <h3 className="__request__box__title">
                          Traveler Sign Up
                        </h3>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="row">
                            <div className="col-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                              <div className="form-group select_t_default">
                                <label htmlFor="select_t_default">
                                  Title <span>*</span>
                                </label>
                                <div className="custom_select_wrap">
                                  <select
                                    {...register("title")}
                                    className="form-control"
                                    id="select_t_default"
                                  >
                                    <option value="Mr.">Mr.</option>
                                    <option value="Mrs." >Mrs.</option>
                                  </select>
                                  <span className="custom_arrow"></span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                  type="text"
                                  name="firstName"
                                  className="form-control"
                                  id="firstName"
                                  placeholder="First Name"
                                  {...register('firstName')}
                                  error = {!!errors.firstName}
                                />
                                <small style={{color: 'red'}}>{errors?.firstName?.message}</small>
                              </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                  type="text"
                                  name="lastName"
                                  className="form-control"
                                  id="lastName"
                                  placeholder="Last Name"
                                  {...register('lastName')}
                                  error = {!!errors.lastName}
                                />
                                <small style={{color: 'red'}}>{errors?.lastName?.message}</small>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="phone">Mobile Number</label>
                                <input
                                  type="tel"
                                  name="phone"
                                  className="form-control"
                                  id="phone"
                                  {...register('phone')}
                                  onChange={(event)=>{
                                    event.target.value = normalizePhoneNumber(event.target.value)
                                }}
                                />
                                 <small style={{color: 'red'}}>{errors?.phone?.message}</small>
                              </div>
                            </div>
                            <h3 className="__request__box__title m_t_20">
                              Login Information
                            </h3>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="email">Email</label>
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Email"
                                  {...register('email')}
                                  error = {!!errors.email}
                                />
                                <small style={{color: 'red'}}>{errors?.email?.message}</small>
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="password">Password</label>
                                <input
                                  name="password"
                                  type="password"
                                  className="form-control"
                                  id="password"
                                  placeholder="Password"
                                  {...register('password')}
                                  error = {!!errors.password}
                                />
                                <small style={{color: 'red'}}>{errors?.password?.message}</small>
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="confirm_password">Confirm Password</label>
                                <input
                                 name="confirm_password"
                                  type="password"
                                  className="form-control"
                                  id="confirm_password"
                                  placeholder="Confirm Password"
                                  {...register('confirm_password')}
                                  error = {!!errors.confirm_password}
                                />
                                <small style={{color: 'red'}}>{errors?.confirm_password?.message}</small>
                              </div>
                            </div>
                            {/* <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="Status">Status</label>
                                <input
                                  name="Status"
                                  type="number"
                                  defaultValue= "1"
                                  className="form-control"
                                  id="Status"
                                  placeholder="gives 0 or 1"
                                  {...register('Status')}
                                  error = {!!errors.Status}
                                />
                                <small style={{color: 'red'}}>{errors?.Status?.message}</small>
                              </div>
                            </div> */}
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="select_checkbox">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    name="acceptTerms"
                                    {...register('acceptTerms')}
                                    error = {!!errors.acceptTerms}
                                  />
                                  <small style={{color: "red"}}>{errors?.acceptTerms?.message}</small>
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    I agree to the Privacy Policy and User
                                    Agreement
                                  </label>
                                </div>
                              </div>
                            </div> 
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="__btn__wrap m_t_20">
                                <button type="submit" className="__save" >Submit</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        <Footer />
        </>
    )
}

export default CustomeSignUp
