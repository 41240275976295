import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
const BasicImgCard = ({blogId, category, currentPage,limit}) => {
  const baseUrl = 'https://admin.buyairticket.com.bd'
  const [data, setData] = useState([])
  // const [btitle, setBtitle] = useState("");
  // const [dTitle, setDTitle] = useState(false)
  // console.log("blogg>>>>>>", btitle, dTitle);
  // if(dTitle){
  //   const lTitle = localStorage.getItem("title")
  //   document.title = lTitle;
  // }
  // console.log(blogId,category,currentPage );
  const fetchUrl = ()=>{
    fetch(`${baseUrl}/api/blog/getblogs/${currentPage}/10`)
    .then(res => res.json())
    .then(data => {
      const listWithCategory =  data.DataList.filter(blog => blog.Category === category);
      // console.log(listWithCategory);
      setData(listWithCategory);
    })
    
  }
  
  useEffect(()=>{
    fetchUrl()
    window.scrollTo(0, 0);
  },[category,currentPage])
  console.log(data);
  return (
    <>
      {data.map(dt =>
      {const title = dt?.Title?.replace(/\s+/g, '-');
      const BlogImage = dt?.Image;
         return(
        <div key={dt.Id} className="__img__card">
          <Link to={`/${title}`}  className="__card__img">
            {BlogImage ?
              <img src={`${baseUrl}/img/blog/${dt.Image}`} alt="blog Image" />
              :
              <img src="/assets/images/default-image.jpg"  alt=""/>
            }
          </Link>
          <div className="__card__info">
            <Link to={`/${title}`}  className="__info__title">{dt.Title.slice(0,60)}<span>{dt.Title.slice(0,60).length === 60 ? "...": ""}</span></Link>
            <div className="__info__review">
              
              <ul className="__review__star">
              {/* { console.log(parseFloat(dt.Rating))} */}
              {
                parseFloat(dt.Rating) >= 1.0 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
              }
              {
                parseFloat(dt.Rating) === 1.5 &&<li className="__info__review__starHalf"></li>
              }
              {
                parseFloat(dt.Rating) >= 2.0 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
              }
              {
                parseFloat(dt.Rating) === 2.5 &&<li className="__info__review__starHalf"></li>
              }
              {
                parseFloat(dt.Rating) >= 3.0 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
              }
              {
                parseFloat(dt.Rating) === 3.5 &&<li className="__info__review__starHalf"></li>
              }
              {
                parseFloat(dt.Rating) >= 4.0 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
              }
              {
                parseFloat(dt.Rating) === 4.5 &&<li className="__info__review__starHalf"></li>
              }
              {
                parseFloat(dt.Rating) >= 5.0 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
              }
              </ul>
              <div className="__info__review__number">{dt.Comments} reviews</div>
            </div>
          </div>
        </div>)}
        )}
    </>
  );
};

export default BasicImgCard;
