import { useHistory } from "react-router-dom";
import { Context, useGlobalContext } from "../context/Context";
import { HashLink as Link } from 'react-router-hash-link';
import { useEffect, useState } from "react";

const SidebarNav = () => {
  const {setIsLogin, googleUser} = useGlobalContext(Context);
  const history = useHistory();
  const baseURL = `https://admin.buyairticket.com.bd`;
    const currentUser = JSON.parse(sessionStorage.getItem("user"));
    const sessionEmail = currentUser?.Email;
    console.log(sessionEmail);
    const Image = currentUser?.ProfileImage;
    const photo = currentUser?.photo;
    const profileImageStyle={
      height: "40px",
      width: "40px",
      borderRadius: "50%"
    }
    const [userInfo, setUserInfo] = useState({});
    console.log(userInfo);
    const {ProfileImage, FirstName} = userInfo;
    const fetchUserImage = ()=>{
      fetch(`${baseURL}/api/user/getuserbyemail/${sessionEmail}`)
      .then(res => res.json())
      .then(result => {
        console.log(result);
        setUserInfo(result);
      })
    }
    useEffect(()=>{
      fetchUserImage()
    },[])
  return (
    <div className="__sidebar__nav">
      <ul>
        <li className="active">
          <Link to="#personalDetails">
          {Image? <img src={`${baseURL}/img/profile/${Image}`} alt="" style={profileImageStyle} />:
              photo? <img src={photo} style={profileImageStyle}/>:
              <img src="/assets/images/user-icon.svg" />

            }
            <span>Personal Details</span>
          </Link>
        </li>
        <li>
          <Link to="#changePassword">
              <img src="/assets/images/user-icon.svg" />
            <span>Change Password</span>
          </Link>
        </li>
        <li>
          <a href="">
            <img src="/assets/images/user-icon.svg" />
            <span>Create Traveler</span>
          </a>
        </li>
        <li>
          <a href="">
            <img src="/assets/images/user-icon.svg" />
            <span>View Traveler</span>
          </a>
        </li>
        <li>
          <a href="">
            <img src="/assets/images/user-icon.svg" />
            <span>Promo Codes</span>
          </a>
        </li>
        <li>
          <a href="">
            <img src="/assets/images/user-icon.svg" />
            <span>Promo Codes</span>
          </a>
        </li>
        <li>
          <a href="">
            <img src="/assets/images/user-icon.svg" />
            <span>Tell a Friend</span>
          </a>
        </li>
        <li>
          <Link to
            onClick={()=>{
              sessionStorage.clear();
              localStorage.clear()
              setIsLogin(false);
              history.push("/")
            }}
          >
            <img src="/assets/images/user-icon.svg" />
            <span>LogOut</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SidebarNav;
