import React, { useEffect, useState } from 'react'
import {Helmet} from "react-helmet";
import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import BasicImgCard from "../../components/basicImgCard";
import Pagination from "../../components/pagination";
import axios from "axios";
import { Link, useLocation, useParams, Redirect } from 'react-router-dom';
import Comments from './Comments';
import CommentRating from './CommentRating';
import { Context, useGlobalContext } from '../../context/Context';
import FaqList from './FaqList';
import ReactPaginate from 'react-paginate';

const CustomBlogPage = () => {
  const location = useLocation();
  const {isLogin, setIsLogin} = useGlobalContext(Context);
  const currentUser = JSON.parse(sessionStorage.getItem("token"));
  const localUser = JSON.parse(sessionStorage.getItem("user"));
  // console.log(localUser, isLogin);
  if(localUser){
    setIsLogin(true);
  }

  // comment
  const [click, setClick] = useState(false);
  // console.log("click:", click, "isLogin",isLogin, "!isLogin:", !isLogin);
  const {review,avgReview} = useGlobalContext(Context);
  const baseUrl = 'https://admin.buyairticket.com.bd'
  const [data, setData] = useState({});
  const {title } = useParams();
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] =useState(1)
  // console.log(title.replace(/-/g, ' '));
  const blogIcon = data?.AirIconImage;
  const blogLimit = 10;
  const fetchUrl = ()=>{
    fetch(`${baseUrl}/api/blog/getblogs/1/90`)
    .then(res => res.json())
    .then(data => {
      const blog = data?.DataList?.find(blogId => blogId.Title === title.replace(/-/g, ' '))
      setData(blog);
      // console.log(blog)
      // console.log(data?.DataList);
      const total = data?.TotalItems;
      setPageCount(Math.ceil(total/blogLimit))
    })
  }
  useEffect(()=>{
    window.scrollTo(0, 0);
    fetchUrl()
  },[title])
  const list_of_group ={
    height:"35px",
    paddingTop:"9px"
  }
  const handlePageClick = (data)=>{
    let currentPage = data.selected + 1;
    // console.log(currentPage)
    setCurrentPage(currentPage);
  }
    return (
        <React.Fragment>
        <Navigation />
        <div className="__page__wrap" id="blogDetails">
          <div className="container container-xl container-xxl">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                <div className="__blog__wrap spacing_l">
                  <div className="__dangerously__html">
                    <div className="__youtube__video">
                    <iframe
                        width="100%"
                        height="345"
                        src={`https://www.youtube.com/embed/${data?.YoutubeLink}`}
                      ></iframe>
                      {/* <img style={{width: "100%", borderRadius:"10px"}} src={`${baseUrl}/img/blog/${data?.Image}`} alt="" /> */}
                    </div>
                    <section className="__blog__info">
                      <div className="__blog__head">
                        <h1 className="__blog__head__title">
                          <span>{ blogIcon?
                            <img style={{width: "50px", height:"50px"}} src={`${baseUrl}/img/blog/${data?.AirIconImage}`}/>
                            : <img style={{width: "30px", height:"30px"}} src="/assets/images/website-logo.png" alt="" />
                          }</span> {data?.Title}
                        </h1>
                        <div className="__info__review">
                          <ul className="__review__star">
                            {/* {avgReview} */}
                            {
                              avgReview >= 1 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
                            }
                            {/* {
                              avgReview === 1.5 &&<li className="__info__review__starHalf"></li>
                            } */}
                            {
                              avgReview >= 2 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
                            }
                            {/* {
                              avgReview === 2.5 &&<li className="__info__review__starHalf"></li>
                            } */}
                            {
                              avgReview >= 3 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
                            }
                            {/* {
                              avgReview === 3.5 &&<li className="__info__review__starHalf"></li>
                            } */}
                            {
                              avgReview >= 4 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
                            }
                            {/* {
                              avgReview === 4.5 &&<li className="__info__review__starHalf"></li>
                            } */}
                            {
                              avgReview >= 5 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
                            }
                          </ul>
                          <div className="__info__review__number">
                          {review} reviews
                          </div>
                        </div>
                        <div className="__info__list">
                          <li style={{maxWidth: "350px"}}>Dhaka Office: {data?.Address}</li>
                          <li style={list_of_group}>
                            <a href={`tel:${data?.PhoneNumber}`}>{data?.PhoneNumber}</a>
                          </li>
                          <li style={list_of_group}>
                            <a href={data?.Website} target="_blank">website</a>
                          </li>
                          <li style={list_of_group}>
                            <a href={data?.WebUrl} target="_blank">Covid 19 Update</a>
                          </li>
                        </div>
                      </div>
                      <div className="__widgets__help d-lg-none">
                        <h4 className="__widgets__help__title">Need Help ?</h4>
                        <ul className="__widgets__helpline__list">
                          <li>
                            <a href="tel:09678742742">
                            <img src="/assets/images/phone.svg" />
                            <span>09678 742 742</span>
                            </a>
                          </li>
                          <li>
                            <img src="/assets/images/messenger.svg" />
                            <a href="https://www.facebook.com/messages/t/100214992273830" target="_blank">
                              <span>Link</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="__blog__body">
                        {/* <h2 className="__blog__title">
                         {data?.Title}
                        </h2> */}
                        {/* <p className="__blog_author">Jone Levere</p>
                        <p className="__blog_pdate">6hr Ago</p> */}
                        
                        <p dangerouslySetInnerHTML={{__html: data?.Description}}/>
                      </div>
                      <Helmet>
                        <meta charSet="utf-8" />
                        <title>{data?.Title}</title>
                        <meta name="keyword" content={data?.MetaKeyword} />
                        <meta name="description" content={data?.MetaDetails?.replace(/(<([^>]+)>)/ig, "")} />
                        <script type="application/ld+json">{`
                              "@context": "http://schema.org",
                              "@type": "Blog",
                              "id:": ${data?.Id},
                              "website": ${data?.Website},
                              "headline": ${data?.Title}
                              "phone": ${data?.PhoneNumber}, 
                              "category": ${data?.Category},
                              "address":${data?.Address},
                              "rating:${data?.Rating}
                            }
                          `}</script>
                      </Helmet>
                    </section>
                    {/* { isLogin && */}
                    <FaqList BlogId={data?.Id} />

                    <section className="__comments__view">
                      <div className="__comments__write__heading">
                        <Link className="__write__comment">
                          <img src="/assets/images/w-c.svg" />
                          <span onClick={()=>{setClick(true)}}>Write Comment</span>
                        </Link>
                        <div>
                          { 
                            click && isLogin &&<CommentRating BlogId={data?.Id} />
                          }
                        </div>
                        {click && !isLogin && (
                          <Redirect to={{
                            pathname: "/login",
                            state: { from: `/${title}` },
                          }}/>
                        )}
                      </div>
                    </section> 
                    {/* } */}
                    <Comments blogId = {data?.Id}/>
                    <div className="__pagination__container centerEl">
                      {/* <Pagination /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="right__sideEl">
                  <div className="p_t_60"></div>
                  <div className="__widgets__help d-none d-lg-block">
                    <h4 className="__widgets__help__title">Need Help ?</h4>
                    <ul className="__widgets__helpline__list">
                      <li>
                        <a href="tel:09678742742">
                        <img src="/assets/images/phone.svg" />
                        <span>09678 742 742</span>
                        </a>
                      </li>
                      <li>
                        <img src="/assets/images/messenger.svg" />
                        <a href="https://www.facebook.com/messages/t/100214992273830" target="_blank">
                          <span>Link</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="__right__suggestions">
                    <BasicImgCard blogId = {data?.Id} limit={blogLimit} currentPage={currentPage}  category={data?.Category} />
                  </div>
                </div>
                <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
}

export default CustomBlogPage
