import React, { Component } from "react";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";

class Feedback extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navigation />
        <div className="__page__wrap spacing_l">
          <div className="container container-lg container-xl container-xxl">
            <div className="row">
              <div className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 mx-auto">
                <div className="__feedback__page">
                  <p>Thank you in advance for your feedback.</p>
                  <p>
                    We’re unable to respond to support queries raised via this
                    form. Please visit our Customer Support Page if you require
                    further assistance with your booking.
                  </p>

                  <div className="__star__rating__wrap">
                    <h6 className="__star__rating__title">
                      Overall Satisfaction with Page *
                    </h6>
                    <ul className="__star__rating">
                      <li>
                        <div>
                          <span>1</span>{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.047"
                            height="12.401"
                            viewBox="0 0 13.047 12.401"
                          >
                            <g
                              id="Group_42"
                              dataName="Group 42"
                              transform="translate(-414.037 -314.991)"
                            >
                              <path
                                id="Path_46"
                                dataName="Path 46"
                                d="M424.58,327.392l-4.025-2.924-4.008,2.912-.015-.009,1.531-4.714-4.026-2.924,0-.014h.073q2.413,0,4.827,0a.088.088,0,0,0,.1-.075q.741-2.289,1.485-4.576c.007-.022.015-.044.027-.078.011.029.018.047.024.065q.747,2.3,1.493,4.6a.078.078,0,0,0,.091.064h4.922c-.028.023-.043.037-.059.049q-1.955,1.419-3.909,2.837a.1.1,0,0,0-.043.137q.746,2.286,1.487,4.574C424.567,327.336,424.571,327.357,424.58,327.392Z"
                              />
                            </g>
                          </svg>
                        </div>
                        <span className="bottom__text">
                          <span className="__s__text">Very</span>
                          <span className="__s__text">dissatisfied</span>
                        </span>
                        <br />
                      </li>
                      <li>
                        <div>
                          <span>2</span>{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.047"
                            height="12.401"
                            viewBox="0 0 13.047 12.401"
                          >
                            <g
                              id="Group_42"
                              dataName="Group 42"
                              transform="translate(-414.037 -314.991)"
                            >
                              <path
                                id="Path_46"
                                dataName="Path 46"
                                d="M424.58,327.392l-4.025-2.924-4.008,2.912-.015-.009,1.531-4.714-4.026-2.924,0-.014h.073q2.413,0,4.827,0a.088.088,0,0,0,.1-.075q.741-2.289,1.485-4.576c.007-.022.015-.044.027-.078.011.029.018.047.024.065q.747,2.3,1.493,4.6a.078.078,0,0,0,.091.064h4.922c-.028.023-.043.037-.059.049q-1.955,1.419-3.909,2.837a.1.1,0,0,0-.043.137q.746,2.286,1.487,4.574C424.567,327.336,424.571,327.357,424.58,327.392Z"
                              />
                            </g>
                          </svg>
                        </div>
                        <span></span>
                      </li>
                      <li>
                        <div>
                          <span>3</span>{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.047"
                            height="12.401"
                            viewBox="0 0 13.047 12.401"
                          >
                            <g
                              id="Group_42"
                              dataName="Group 42"
                              transform="translate(-414.037 -314.991)"
                            >
                              <path
                                id="Path_46"
                                dataName="Path 46"
                                d="M424.58,327.392l-4.025-2.924-4.008,2.912-.015-.009,1.531-4.714-4.026-2.924,0-.014h.073q2.413,0,4.827,0a.088.088,0,0,0,.1-.075q.741-2.289,1.485-4.576c.007-.022.015-.044.027-.078.011.029.018.047.024.065q.747,2.3,1.493,4.6a.078.078,0,0,0,.091.064h4.922c-.028.023-.043.037-.059.049q-1.955,1.419-3.909,2.837a.1.1,0,0,0-.043.137q.746,2.286,1.487,4.574C424.567,327.336,424.571,327.357,424.58,327.392Z"
                              />
                            </g>
                          </svg>
                        </div>
                        <span></span>
                      </li>
                      <li className="active">
                        <div>
                          <span>4</span>{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.047"
                            height="12.401"
                            viewBox="0 0 13.047 12.401"
                          >
                            <g
                              id="Group_42"
                              dataName="Group 42"
                              transform="translate(-414.037 -314.991)"
                            >
                              <path
                                id="Path_46"
                                dataName="Path 46"
                                d="M424.58,327.392l-4.025-2.924-4.008,2.912-.015-.009,1.531-4.714-4.026-2.924,0-.014h.073q2.413,0,4.827,0a.088.088,0,0,0,.1-.075q.741-2.289,1.485-4.576c.007-.022.015-.044.027-.078.011.029.018.047.024.065q.747,2.3,1.493,4.6a.078.078,0,0,0,.091.064h4.922c-.028.023-.043.037-.059.049q-1.955,1.419-3.909,2.837a.1.1,0,0,0-.043.137q.746,2.286,1.487,4.574C424.567,327.336,424.571,327.357,424.58,327.392Z"
                              />
                            </g>
                          </svg>
                        </div>
                        <span></span>
                      </li>
                      <li>
                        <div>
                          <span>5</span>{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.047"
                            height="12.401"
                            viewBox="0 0 13.047 12.401"
                          >
                            <g
                              id="Group_42"
                              dataName="Group 42"
                              transform="translate(-414.037 -314.991)"
                            >
                              <path
                                id="Path_46"
                                dataName="Path 46"
                                d="M424.58,327.392l-4.025-2.924-4.008,2.912-.015-.009,1.531-4.714-4.026-2.924,0-.014h.073q2.413,0,4.827,0a.088.088,0,0,0,.1-.075q.741-2.289,1.485-4.576c.007-.022.015-.044.027-.078.011.029.018.047.024.065q.747,2.3,1.493,4.6a.078.078,0,0,0,.091.064h4.922c-.028.023-.043.037-.059.049q-1.955,1.419-3.909,2.837a.1.1,0,0,0-.043.137q.746,2.286,1.487,4.574C424.567,327.336,424.571,327.357,424.58,327.392Z"
                              />
                            </g>
                          </svg>
                        </div>
                        <span className="bottom__text">
                          <span className="__s__text">Very</span>
                          <span className="__s__text">satisfied</span>
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="__like__wrap">
                    <div className="__like__info">
                      How likely is it that you would recommend Buyair Ticket to
                      a friend or colleague with 0 being not likely and 10 being
                      extremely likely?
                    </div>
                    <ul>
                      <li>1</li>
                      <li>2</li>
                      <li>3</li>
                      <li>4</li>
                      <li>5</li>
                      <li>6</li>
                      <li>7</li>
                      <li>8</li>
                      <li>9</li>
                      <li className="active">10</li>
                    </ul>
                  </div>
                  <div className="form-group textarea_default">
                    <label htmlFor="formControlTextarea">
                      Additional feedback <span className="__required">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="formControlTextarea"
                      rows="3"
                      placeholder="Please include anything else you’d like us to know"
                    ></textarea>
                  </div>

                  <div className="form-group input_t_textWLabel">
                    <label htmlFor="email">Email address (optional)</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="example@email.com"
                    />
                  </div>
                  <p>
                    The email address you provide helps us further investigate
                    issues with your account and will only be used for that
                    purpose.
                  </p>
                  <div className="__hr__line"></div>
                  <p>
                    <span className="__required">*</span> = required
                  </p>

                  <div className="__submit__cta">
                    <button className="__save">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Feedback;
