import React, { Component } from "react";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";

class flightSearchDetail extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navigation />
        <div className="__page__wrap spacing_l">
          <div className="container container-lg container-xl container-xxl">
            <div className="__dangerously__html__detail">
              <h3 className="__main__title">
                BAGGAGE ALLOWANCE &amp; POLICIES
              </h3>
              <ul className="__tabs">
                <li className="active">
                  <a href="">Booking Information</a>
                </li>
                <li>
                  <a href="">Baggage Allowance</a>
                </li>
                <li>
                  <a href="">Cancellation &amp; Change Policies</a>
                </li>
              </ul>

              <ul>
                <li>
                  <img src="/assets/images/c-start.svg" alt="" />
                  Make unlimited changes without processing fees
                </li>
                <li>
                  <img src="/assets/images/c-start.svg" alt="" />
                  Once payment is confirmed, tickets will be issued within 2
                  hours
                </li>
                <li className="__color__o">
                  <img src="/assets/images/c-start.svg" alt="" />
                  Passengers with a standard frequent flyer membership will earn
                  approximately 4558 miles/points.
                </li>
              </ul>
              <p className="__cotation">
                *This information is for reference only. Actual allocation of
                miles/points is dependent on the airline and airline policy.Data
                provided by: 30k
              </p>
              <h6 className="m_t_20">Baggage Allowance</h6>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th colSpan="2">
                        Dhaka - Abu Dhabi, Abu Dhabi - New York
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{ verticalAlign: "middle", textAlign: "center" }}
                      >
                        Adult Ticket
                      </td>
                      <td>
                        <p>
                          Checked Baggage:2 piece(s) per person, 23kg each piece
                        </p>
                        <p>Each piece cannot exceed 90*72*45CM in size.</p>

                        <p>Carry-on Baggage:7kg per person</p>
                        <p>Each piece cannot exceed 56*36*23CM in size.</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h6 className="m_t_20">Cancellation &amp; Change Policies</h6>
              <p className="__color__o inlineEl">
                <img src="/assets/images/c-start.svg" alt="" />
                COVID-19 Flexibooking Policy
              </p>
              <p>
                If date changes are made between 16:13, May 18, 2021 (local
                time) and 00:40, June 30, 2021 (local time), and the new
                departure date is after May 18, 2021, any price difference will
                be charged by the airline. Departure and arrival points cannot
                be changed. Endorsements to other airlines not permitted. For
                date changes due to involuntary flight changes, please refer to
                the airline's policies and regulations.
              </p>
              <p className="__color">
                If the requirements of the Flexibooking policy are not met, the
                airline will charge change fees according to the policies shown
                in the chart below.
              </p>
              <p className="inlineEl">
                <img src="/assets/images/c-start.svg" alt="" />
                Cancellation Notice
              </p>
              <p>
                Dhaka - Abu Dhabi, Abu Dhabi - New York: Due to the impact of
                COVID-19 worldwide, refunds will be processed in accordance with
                the latest airline policies. Please note that at the airline's
                discretion, refunds may be made in the form of airline vouchers.
                The final decision regarding the refund method will be made by
                the airline. Some airlines may charge processing fees for flight
                changes or cancellations made due to flight schedule changes.
                Actual fees charged will depend on the final decision by the
                airline.
              </p>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th colSpan="3">
                        <div className="inlineEl">
                          <img src="/assets/images/h.svg" alt="" /> Adult Ticket{" "}
                          <span>(Price per passenger)</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        rowSpan="2"
                        style={{ verticalAlign: "middle", textAlign: "center" }}
                      >
                        Cancellation Fee
                      </td>
                      <td>More than 3 hours prior to departure</td>
                      <td>Non-refundable, tax refundable: US$163.</td>
                    </tr>
                    <tr>
                      <td>Within 3 hours of departure</td>
                      <td>Non-refundable, tax refundable: US$163.</td>
                    </tr>
                    <tr>
                      <td
                        rowSpan="3"
                        style={{ verticalAlign: "middle", textAlign: "center" }}
                      >
                        Change Fee
                      </td>
                      <td>More than 3 hours prior to departure</td>
                      <td>US$254</td>
                    </tr>
                    <tr>
                      <td>Within 3 hours of departure</td>
                      <td>US$307</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        US$227.25 charged if within 96 hour(s) before departure.
                        When date changes result in a difference in ticket
                        price, this difference must be paid for. Once a ticket
                        has been changed, the change service fee that has
                        already been paid is non-refundable according to the
                        airline's policies and regulations if any further
                        changes are made
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="__color">
                If your flight is changed due to external factors (for example
                if your flight is delayed or the plane type is changed) and you
                need to change your flight, please refer to the latest airline
                policies.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default flightSearchDetail;
