import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ForgotPassword = () => {
    const [userEmail, setUserEmail] = useState("");
    // console.log(userEmail);
    const handleForgetPassword =()=>{
      try{
        fetch(`https://admin.buyairticket.com.bd/api/mail/sendpassword/${userEmail}`,{
          method:'POST',
          headers: { 
            "Content-Type": "application/json",
            'Accept': 'application/json, text/plain, */*',
          },
          body: userEmail
        })
        .then( res =>res.json())
        .then(success =>{
          console.log(success);
          if(success === 1){
            alert("We sent your current password. Please check your email!")
          }
          else{
            alert("Enter your correct email address.")
          }
         })
        
      }catch(err){
        console.log(err)
      }
      // console.log(userEmail);
    }
    return (
        <React.Fragment>
        <div className="__login">
          <div className="__login__content">
            <div className="container container-xl container-xxl">
              <div className="row">
                <div className="col-md-5 __order__2">
                  <div className="__login__inputs">
                    <div className="__social__login">
                      <button className="__google">Forgot Password</button>
                    </div>
                    <div className="inputs">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          name = "email"
                          className="form-control"
                          id="email"
                          placeholder="Enter your email address"
                          onChange={(e)=> {setUserEmail(e.target.value)}}
                        />
                      </div>
                      <p className="__f__password">
                        <Link to="/login">Back to login page</Link>
                      </p>
                      <div className="CTA__wrap">
                        <button onClick={handleForgetPassword} className="__submit">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 __order__1">
                  <div className="__login__img">
                    <img src="/assets/images/login.PNG" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
}

export default ForgotPassword
