import React, { Component } from "react";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";

class SignUp extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navigation />
        <div className="page__wrap spacing_l">
          <div className="container container-lg container-xl container-xxl">
            <div className="row">
              <div className="col-12 col-md-10 col-lg-9 col-xl-9 col-xxl-9 mx-auto">
                <div className="__request__box__wrap">
                  <div className="row">
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <div className="__request__box__leftImg">
                        <img src="./assets/images/signup-left.png" />
                      </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                      <div className="__request__box">
                        <h3 className="__request__box__title">
                          Traveler Sign Up
                        </h3>
                        <form action="">
                          <div className="row">
                            <div className="col-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                              <div className="form-group select_t_default">
                                <label htmlFor="select_t_default">
                                  Title <span>*</span>
                                </label>
                                <div className="custom_select_wrap">
                                  <select
                                    className="form-control"
                                    id="select_t_default"
                                  >
                                    <option>Mr.</option>
                                    <option>Mrs.</option>
                                  </select>
                                  <span className="custom_arrow"></span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="email">First Name</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="First Name"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="email">Last Name</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="email">Mobile Number</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Mobile Number"
                                />
                              </div>
                            </div>
                            <h3 className="__request__box__title m_t_20">
                              Login Information
                            </h3>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="email">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="email">Password</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Password"
                                />
                              </div>
                            </div>
                            <p className="__request__box__note">
                              Password must contain at least one uppercase
                              letter, one lowercase letter, one number, one
                              special character(@#$*) and must be longer than
                              eight characters.
                            </p>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="form-group input_t_textWLabel">
                                <label htmlFor="email">Confirm Password</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Confirm Password"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="select_checkbox">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    I agree to the Privacy Policy and User
                                    Agreement
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="__btn__wrap m_t_20">
                                <button className="__save">Submit</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="__request__box__wrap sign__up__information">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <p className="__mark__text">
                        Welcome to the B2B sign up page!Getting your B2B account
                        activated is as easy as completing this form. Once
                        approved and activated you will qualify for better
                        travel rates on flights, hotels and car hire worldwide.
                        If you are an individual business traveller, business
                        owner or a corporate travel account manager you are
                        invited to participate.
                      </p>
                      <div className="__request__box">
                        <h3 className="__request__box__title">
                          Company Information
                        </h3>
                        <div className="row">
                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group input_t_textWLabel">
                              <label htmlFor="email">Company Name: *</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Company Name"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group input_t_textWLabel">
                              <label htmlFor="email">Website</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Website"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group input_t_textWLabel">
                              <label htmlFor="email">Address 1: *</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Address 1"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group input_t_textWLabel">
                              <label htmlFor="email">Address 2: *</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Address 2"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group select_t_default">
                              <label htmlFor="select_t_default">
                                Country: <span>*</span>
                              </label>
                              <div className="custom_select_wrap">
                                <select
                                  className="form-control"
                                  id="select_t_default"
                                >
                                  <option>Select Country</option>
                                  <option>Mrs.</option>
                                </select>
                                <span className="custom_arrow"></span>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group select_t_default">
                              <label htmlFor="select_t_default">
                                State: <span>*</span>
                              </label>
                              <div className="custom_select_wrap">
                                <select
                                  className="form-control"
                                  id="select_t_default"
                                >
                                  <option>N/A - Not Applicable</option>
                                  <option>Mrs.</option>
                                </select>
                                <span className="custom_arrow"></span>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group input_t_textWLabel">
                              <label htmlFor="email">City: *</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="City"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group input_t_textWLabel">
                              <label htmlFor="email">Zip: *</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Zip"
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="__company__type">
                              <div className="__company__type__title">
                                Company Type : *
                              </div>
                              <div className="__company__type__select">
                                <div className="radio_select">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input custom_form_check_input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label custom_form_check_label"
                                      htmlFor="flexRadioDefault1"
                                    >
                                      Proprietorship
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input custom_form_check_input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                    />
                                    <label
                                      className="form-check-label custom_form_check_label"
                                      htmlFor="flexRadioDefault2"
                                    >
                                      Partnership/Company
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input custom_form_check_input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault3"
                                    />
                                    <label
                                      className="form-check-label custom_form_check_label"
                                      htmlFor="flexRadioDefault3"
                                    >
                                      Individual
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h3 className="__request__box__title m_t_20">
                            Contact Details
                          </h3>
                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group select_t_default">
                              <label htmlFor="select_t_default">
                                Title: <span>*</span>
                              </label>
                              <div className="custom_select_wrap">
                                <select
                                  className="form-control"
                                  id="select_t_default"
                                >
                                  <option>Mr.</option>
                                  <option>Mrs.</option>
                                </select>
                                <span className="custom_arrow"></span>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group input_t_textWLabel">
                              <label htmlFor="email">First Name: *</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="First Name"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group input_t_textWLabel">
                              <label htmlFor="email">Last Name: *</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Last Name"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group input_t_textWLabel">
                              <label htmlFor="email">Email: *</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Email"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group input_t_textWLabel">
                              <label htmlFor="email">Contact Phone: *</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Contact Phone"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="form-group input_t_textWLabel">
                              <label htmlFor="email">Mobile Phone: *</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Mobile Phone"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <div className="centerEl">
                              <div className="select_checkbox">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    I agree to the Privacy Policy and{" "}
                                    <a href="">Terms &amp; Conditions</a> of
                                    BuyAirTicket.
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <div className="__btn__wrap m_t_20 centerEl">
                              <button className="__save">Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default SignUp;
