import React from 'react';
import { Redirect, Route } from 'react-router';
import jwt_decode from "jwt-decode";

const PrivateRoute = ({children, ...rest}) => {
    const currentUser = JSON.parse(sessionStorage.getItem("user"));
    const userEmail = currentUser?.Email;
  const isLogIn = currentUser?.isSignIn
    console.log(userEmail);
    const isLoggedIn = () => {
        const token = sessionStorage.getItem('token');
        if(!token){
          return false;
        }
        const decodedToken = jwt_decode(token);
        // get current time
        const currentTime = new Date().getTime() / 1000;
        // compare the expiration time with the current time
        // will return false if expired and will return true if not expired
        return decodedToken.exp > currentTime;
      }
    return (
        <Route
        {...rest}
        render={({ location }) =>
          (userEmail || isLoggedIn() || isLogIn) ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login-page",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
};

export default PrivateRoute;