import React, { Component, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Context, useGlobalContext } from "../context/Context";
import { Link } from "react-router-dom";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }}
      onClick={onClick}
    >
      <img src="/assets/images/next-a.svg" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }
    }
      onClick={onClick}
    >
      <img src="/assets/images/after-a.svg" />
    </div>
  );
}

const  Column4SliderTravel =(props)=> {
  const baseUrl = `https://admin.buyairticket.com.bd`;
  const {data} = useGlobalContext(Context);
  // const [btitle, setBtitle] = useState("");
  // const [dTitle, setDTitle] = useState(false)
  // console.log("blogg>>>>>>", btitle, dTitle);
  // if(dTitle){
  //   const lTitle = localStorage.getItem("title")
  //   document.title = lTitle;
  // }
  console.log(data);
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      pauseOnHover: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Slider {...settings}>
      {
        data.map((dt, index) =>
          { const title = dt?.Title?.replace(/\s+/g, '-');
            const metaKeyword = dt?.MetaKeyword;
            const metaDes = dt?.MetaDetails
            const BlogImage = dt?.Image;
            // console.log(udata);
            return(
            <div key={index} className="__img__card">
              <p id="metaData" className="d-none d-sm-none d-lg-none d-md-none" dangerouslySetInnerHTML={{__html: metaDes}}></p>
              <Link to={`/${title}`} 
              className="__card__img">
                {BlogImage ?
                  <img src={`${baseUrl}/img/blog/${dt.Image}`} alt="blog Image" />
                  :
                  <img src="/assets/images/default-image.jpg"  alt=""/>
                }
              </Link>
              <div className="__card__info">
                <p className="__info__desc">
                  <Link to={`/${title}`}  className="__info__title">{dt.Title.slice(0,60)}<span>{dt.Title.slice(0,60).length === 60 ? "...": ""}</span></Link>
                </p>
              </div>
            </div>
          )
        })
      }
      </Slider>
    );
}

export default Column4SliderTravel;
