import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Footer =()=> {
  const [click, setClick] = useState(false);
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[click])
    return (
      <footer className="__footer__website spacing_l">
        <div className="container container-xl container-xxl">
          <div className="row">
            <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <div className="__footer__logo">
                <img src="/assets/images/website-logo.png" alt="website-logo" />
              </div>
              <div className="__footer__social__url">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/buyairticketltd" target="_blank">
                      <img src="/assets/images/facebook-icon-2.svg" alt="facebook" />
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/buyairticket/" target="_blank">
                      <img src="/assets/images/instagram-icon-2.svg" alt="instagram" />
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCAF7Xq8kjlOgBjU62LYHrkg" target="_blank">
                      <img src="/assets/images/youtube-icon-2.svg" alt="youtube" />
                      Youtube
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/buyairticket/" target="_blank">
                      <img src="/assets/images/linkin-icon-2.svg" alt="linkedIn" />
                      linkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
              <div className="__footer__mainEl">
                <ul className="__footer__links">
                  <li>
                    <a href="">ABOUT</a>
                  </li>
                  <li>
                    <a href="">JOB AT BATL</a>
                  </li>
                  <li>
                    <a href="">TEAM</a>
                  </li>
                  <li>
                    <a href="">CONTACT US</a>
                  </li>
                  <li onClick={()=>{setClick(true)}}>
                    <Link to="/privacy-policy">PRIVACY POLICY</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-use" onClick={()=>{setClick(true)}}>TERMS OF USE</Link>
                  </li>
                </ul>
                <div className="__footer__search">
                  <div className="input-group input_w_icon">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                    />
                    <span className="input-group-text">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                      >
                        <g
                          id="Group_299"
                          dataName="Group 299"
                          transform="translate(-2145.907 -4679)"
                        >
                          <path
                            id="Path_298"
                            dataName="Path 298"
                            d="M2145.907,4682.693c.033-.2.049-.407.1-.6a3.847,3.847,0,0,1,3.052-3,3.926,3.926,0,0,1,4.687,3.113,3.858,3.858,0,0,1-.725,3.134l-.064.086a.288.288,0,0,1-.03.042.531.531,0,0,1,.1.064q1.382,1.378,2.761,2.76c.16.16.163.25,0,.408-.106.1-.22.2-.33.3h-.118c-.056-.048-.115-.094-.167-.146q-1.36-1.359-2.72-2.72a.781.781,0,0,1-.078-.111l-.089.064a3.893,3.893,0,0,1-3.04.78,3.954,3.954,0,0,1-3.237-3.033c-.045-.2-.067-.409-.1-.614Zm7.083.266a3.124,3.124,0,1,0-3.122,3.127A3.128,3.128,0,0,0,2152.99,4682.959Z"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="__hr__line"></div>
                <div className="__footer__newsletter">
                  <h6 className="__footer__newsletter__title">
                    Sign up for our daily newsletter
                  </h6>
                  <div className="form-group input_t_text input_t_btn">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter your email address"
                    />
                    <button
                      className="btn input__btn"
                      type="button"
                      id="button-addon2"
                    >
                      Sign up
                    </button>
                  </div>
                  <div className="select_checkbox">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        I would like to subscribe to Buy Air Ticket Ltd- BATL
                        newsletter and special email promotions. Buy Air Ticket Ltd- BATL
                        will not sell your email.
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className="__footer__desc">
                  <p>
                    The credit card offers that appear on the website are from
                    credit card companies from which ThePointsGuy.com receives
                    compensation. This compensation may impact how and where
                    products appear on this site (including, for example, the
                    order in which they appear). This site does not include all
                    credit card companies or all available credit card offers.
                    Please view our advertising policy page for more
                    information.
                  </p>
                  <p>
                    Editorial Note: Opinions expressed here are the author’s
                    alone, not those of any bank, credit card issuer, airlines
                    or hotel chain, and have not been reviewed, approved or
                    otherwise endorsed by any of these entities.
                  </p>
                </div> */}
                <ul className="__footer__com__highlights">
                  <li>
                    <a href="https://buyairticket.com" target="_blank">Buyair Ticket.com</a>
                  </li>
                  <li>
                    <a href="https://sociovocal.com" target="_blank">Follower BASIS.com</a>
                  </li>
                  <li>
                    <a href="https://eshoppingmall.xyz" target="_blank">eShopping <br />Mall.xyz</a>
                  </li>
                  <li>
                    <a href="https://skyflicker.com" target="_blank">skyflicker.com</a>
                  </li>
                  <li>
                    <a href="http://batllab.com" target="_blank">BATLLAB.com</a>
                  </li>
                  <li>
                    <a href="http://batl.group" target="_blank">
                      BATL.
                      <br />
                      Group
                    </a>
                  </li>
                  <li>
                    <a href="http://www.oh-dhaka.com" target="_blank">
                      oh-
                      <br />
                      dhaka.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
}

export default Footer;
