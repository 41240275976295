import React, { useContext, useEffect, useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { useForm } from "react-hook-form";
import { Context, useGlobalContext } from '../../context/Context';
import { Redirect } from 'react-router-dom';
const CommentRating = ({BlogId}) => {
    const baseUrl = `https://admin.buyairticket.com.bd`;
    const { setComment} = useGlobalContext(Context);
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [rating, setRating] = useState(0); 
    const [closeCommentfield, setCloseCommentFiled] = useState(true)
    const currentUser = JSON.parse(sessionStorage.getItem("user"));
    const currentUserEmail = currentUser?.Email; 
    const currentUserId = currentUser?.Id;
    const status = 1;
    // console.log(currentUserEmail, currentUserId,BlogId);

    function refreshPage() {
        window.location.reload();
      }
    const ratingChanged = (newRating) => {
        setRating(newRating);
        console.log(newRating);
      };
    // console.log("from contxt",comment);
    const onSubmit =(data)=>{
        const newComment = {
            ...data,
            rating,
            email: currentUserEmail,
            userId: currentUserId,
            blogId: BlogId,
            status,
        }
        fetch(`${baseUrl}/api/comment/createcomment`,{
            method:'POST',
            headers: { 
              "Content-Type": "application/json",
              'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify(newComment)
        })
        .then(success =>{
            if(success){
                alert('comment successfully posted!');
               refreshPage()
            }
            else{
                alert("error")
            }
        })
        
        setComment(newComment);
        // console.log(newComment);
    }
    return (
        <>
            <div style={{width: '100%', alignItems:'center'}}>
                <p style={{marginBottom:'0px'}}>Rating</p>
                <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    size={20}
                    isHalf = {true}
                    edit={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fas fa-star-half-alt"></i>}
                    fullIcon={<i className="fas fa-star"></i>}
                    activeColor="#ffd700"
                />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="inputs">
                        <div className="form-group input_t_textWLabel my-2">
                            <textarea
                            style={{height: '70px'}}
                            type="text"
                            className="form-control"
                            id="comments"
                            name="comments"
                            placeholder="write your comment"
                            {...register("comments",{ required: true })}
                            ></textarea>
                            {errors.comments?.type === 'required' && "First name is required"}
                        </div>
                    </div>
                    <button type="submit" className="btn btn-info" >Post Comment</button>
                </form>
            </div>
        </>
    )
}

export default CommentRating
