import React, { Component } from "react";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import Column3SliderSearch from "../../components/column3SliderSearch";
import Column3SliderExplore from "../../components/column3SliderExplore";
import Column4SliderTravel from "../../components/column4SliderTravel";
import Column3SliderShop from "../../components/column3SliderShop";
import Column3SliderConnect from "../../components/column3SliderConnect";
import Column3SliderSpecial from "../../components/column3SliderSpecial";

class HomePage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navigation />
        <div className="__page__wrap">
          <br />
          <br />
          <section className="__where__to">
            <div className="container container-lg container-xl container-xxl">
              <div className="__where__to__wrap">
                {/* <h3 className="text-center">
                  Find Cheapest Air Tickets
                </h3> */}
                <h1 className="__button__div">
                  <a href="https://buyairticket.com" target="_blank">
                    <button>
                      Find Cheapest Air Tickets
                    </button>
                  </a>
                </h1>
                <br />
                <div className="__other__tabs">
                  <ul className="__left">
                    <li className="active">
                      <a href="https://buyairticket.com" target="_blank">HOLIDAY PACKAGE</a>
                    </li>
                    <li>
                      <a href="https://buyairticket.com" target="_blank">FLIGHTS</a>
                    </li>
                    <li>
                      <a href="https://buyairticket.com" target="_blank">HOTEL</a>
                    </li>
                  </ul>
                  <div className="__search__btn">
                    <button>
                      <img src="/assets/images/search-icon-white.svg" /> Search
                    </button>
                  </div>
                  <ul className="__right">
                    <li>
                      <a href="https://buyairticket.com" target="_blank">BUS</a>
                    </li>
                    <li>
                      <a href="https://buyairticket.com" target="_blank">LAUNCH</a>
                    </li>
                    <li>
                      <a href="https://buyairticket.com" target="_blank">eVisa</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="__imp__info spacing_l">
            <div className="container container-lg container-xl container-xxl">
              <div className="__imp__info__wrap">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="__vh__card">
                      <div className="__card__img">
                        <img src="https://www.alternativeairlines.com/images/aa/responsive/pages/home/usps/search.svg" />
                      </div>
                      <div className="__card__info">
                        <h4 className="__info__title">Easy Flight Search</h4>
                        <p className="__info__desc">
                        Find your flight and choose from over 40 payment methods and 160 currencies
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="__vh__card">
                      <div className="__card__img">
                        <img src="https://www.alternativeairlines.com/images/aa/responsive/pages/home/usps/booking.svg" />
                      </div>
                      <div className="__card__info">
                        <h4 className="__info__title">Secure Booking</h4>
                        <p className="__info__desc">
                          Use our easy online booking system to book flights quick & securely
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="__vh__card">
                      <div className="__card__img">
                        <img src="	https://www.alternativeairlines.com/images/aa/responsive/pages/home/usps/choice.svg" />
                      </div>
                      <div className="__card__info">
                        <h4 className="__info__title">More Choice</h4>
                        <p className="__info__desc">
                          Experience better flights & connections with more than 600 airlines worldwide
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="__vh__card">
                      <div className="__card__img">
                        <img src="https://www.alternativeairlines.com/images/aa/responsive/pages/home/usps/multistop.svg" />
                      </div>
                      <div className="__card__info">
                        <h4 className="__info__title">Multi-Stop</h4>
                        <p className="__info__desc">
                          Use our multi-stop booking tool to discover multiple destinations in a single trip
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="__connect__traveler spacing_l p_b_0">
            <div className="container container-lg container-xl container-xxl">
              <div className="section__title">
                <h4>Connect Traveler</h4>
              </div>
              <div className="__connect__traveler__slider">
                <Column3SliderConnect />
              </div>
            </div>
          </section>

          <section className="__shop__before spacing_l p_b_0">
            <div className="container container-lg container-xl container-xxl">
              <div className="section__title">
                <h4>Shop before Flight</h4>
              </div>
              <div className="__shop__before__slider">
                <Column3SliderShop />
              </div>
            </div>
          </section>

          <section className="__special__discount spacing_l p_b_0">
            <div className="container container-lg container-xl container-xxl">
              {/* <div className="section__title">
                <h4>Special Discount</h4>
              </div> */}

              {/* <div className="rightEl">
                <div className="__tab__items__wrap">
                  <ul className="__tab__items">
                    <li className="__tab__item active">
                      <a href="">All</a>
                    </li>
                    <li className="__tab__item">
                      <a href="">Int’l Flight</a>
                    </li>
                    <li className="__tab__item">
                      <a href="">Bank Offers</a>
                    </li>
                    <li className="__tab__item">
                      <a href="">Domestic Flight</a>
                    </li>
                    <li className="__tab__item">
                      <a href="">Domestic Hotel</a>
                    </li>
                  </ul>
                </div>
              </div> */}

              {/* <div className="__special__discount__slider m_b_40">
                <Column3SliderSpecial />
              </div> */}

              <section className="__flight__lists">
                <div className="__list">
                  <div className="__list__img">
                    <img src="https://buyairticket.com/ibe/images/packages/chennai.jpg" alt="" />
                  </div>
                  <div className="__list__info">
                    <a href="https://www.buyairticket.com/static.action?page=flight/dhaka-to-chennai" target="_blank">
                      <h4 className="__info__title">Dhaka to Chennai flight</h4>
                    </a>
                    <p className="__info__desc">
                    Via - Delhi, Mumbai, Coimbatore, Madurai
                    </p>
                  </div>
                </div>
                <div className="__list">
                <div className="__list__img">
                    <img src="https://buyairticket.com/ibe/images/packages/hydrabad.jpg" alt="" />
                  </div>
                  <div className="__list__info">
                    <a href="https://buyairticket.com/static.action?page=flight/dhaka-to-hyderabad" target="_blank">
                      <h4 className="__info__title">Dhaka to Hyderabad flight</h4>
                    </a>
                    <p className="__info__desc">
                    Via - Delhi, Mumbai, Coimbatore, Madurai
                    </p>
                  </div>
                </div>
                <div className="__list">
                  <div className="__list__img">
                    <img src="https://buyairticket.com/ibe/images/packages/calkata.jpeg" alt="" />
                  </div>
                  <div className="__list__info">
                    <a href="https://buyairticket.com/static.action?page=flight/dhaka-to-kolkata" target="_blank">
                      <h4 className="__info__title">Dhaka to Kolkata flights</h4>
                    </a>
                    <p className="__info__desc">
                      Via - Delhi, Mumbai, Coimbatore, Madurai
                    </p>
                  </div>
                </div>
                <div className="__list">
                  <div className="__list__img">
                    <img src="https://buyairticket.com/ibe/images/packages/delhi.jpg" alt="" />
                  </div>
                  <div className="__list__info">
                    <a href="https://buyairticket.com/static.action?page=flight/dhaka-to-delhi" target="_blank">
                      <h4 className="__info__title">Dhaka to Delhi flights</h4>
                    </a>
                    <p className="__info__desc">
                      Via - Delhi, Mumbai, Coimbatore, Madurai
                    </p>
                  </div>
                </div>
                <div className="__list">
                  <div className="__list__img">
                    <img src="https://buyairticket.com/ibe/images/packages/goa.jpg" alt="" />
                  </div>
                  <div className="__list__info">
                    <a href="https://buyairticket.com/static.action?page=flight/dhaka-to-goa" target="_blank">
                      <h4 className="__info__title">Dhaka to Goa flights</h4>
                    </a>
                    <p className="__info__desc">
                      Via - Delhi, Mumbai, Coimbatore, Madurai
                    </p>
                  </div>
                </div>
                <div className="__list">
                  <div className="__list__img">
                    <img src="https://buyairticket.com/ibe/images/packages/mumbai.jpeg" alt="" />
                  </div>
                  <div className="__list__info">
                    <a href="https://buyairticket.com/static.action?page=flight/dhaka-to-mumbai" target="_blank">
                      <h4 className="__info__title">Dhaka to Mumbai flights</h4>
                    </a>
                    <p className="__info__desc">
                      Via - Delhi, Mumbai, Coimbatore, Madurai
                    </p>
                  </div>
                </div>
                <div className="__list">
                  <div className="__list__img">
                    <img src="https://buyairticket.com/ibe/images/packages/bangalore.jpg" alt="" />
                  </div>
                  <div className="__list__info">
                    <a href="https://buyairticket.com/static.action?page=flight/dhaka-to-bangalore" target="_blank">
                      <h4 className="__info__title">Dhaka to Bangalore flights</h4>
                    </a>
                    <p className="__info__desc">
                      Via - Delhi, Mumbai, Coimbatore, Madurai
                    </p>
                  </div>
                </div>
                <div className="__list">
                  <div className="__list__img">
                    <img src="https://buyairticket.com/ibe/images/packages/pune.jpg" alt="" />
                  </div>
                  <div className="__list__info">
                    <a href="https://buyairticket.com/static.action?page=flight/dhaka-to-pune" target="_blank">
                      <h4 className="__info__title">Dhaka to Pune flights</h4>
                    </a>
                    <p className="__info__desc">
                      Via - Delhi, Mumbai, Coimbatore, Madurai
                    </p>
                  </div>
                </div>
                <div className="__list">
                  <div className="__list__img">
                    <img src="https://buyairticket.com/ibe/images/packages/jaipur.jpg" alt="" />
                  </div>
                  <div className="__list__info">
                    <a href="https://buyairticket.com/static.action?page=flight/dhaka-to-jaipur" target="_blank">
                      <h4 className="__info__title">Dhaka to Jaipur flights</h4>
                    </a>
                    <p className="__info__desc">
                      Via - Delhi, Mumbai, Coimbatore, Madurai
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </section>

          <section className="__travel__blog spacing_l p_b_0">
            <div className="container container-lg container-xl container-xxl">
              <div className="section__title">
                <h4>Travel Blog</h4>
              </div>
              <div className="__travel__blog__slider">
                <Column4SliderTravel />
              </div>
            </div>
          </section>

          <section className="__explore__deal spacing_l p_b_0">
            <div className="container container-lg container-xl container-xxl">
              <div className="section__title">
                <h4>Explore the world</h4>
              </div>
              <div className="__explore__deal__slider">
                <Column3SliderExplore />
              </div>
            </div>
          </section>
          {/* <section className="__search__deal spacing_l p_b_0">
            <div className="container container-lg container-xl container-xxl">
              <div className="section__title">
                <h4>Search Deals</h4>
              </div>
              <div className="__search__deal__slider">
                <Column3SliderSearch />
              </div>
            </div>
          </section> */}

          {/* <section className="__popular__cities spacing_l">
            <div className="container container-lg container-xl container-xxl">
              <div className="section__title">
                <h4>Popular Cities</h4>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                  <a href="">
                    <div className="__img__card __ontop__leftEl">
                      <div className="__card__img">
                        <img src="/assets/images/thumb-5.png" />
                      </div>
                      <div className="__info__ontop__left">
                        <p>
                          <img src="/assets/images/plane.svg" />
                          Flights To Accra
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                  <a href="">
                    <div className="__img__card __ontop__leftEl">
                      <div className="__card__img">
                        <img src="/assets/images/thumb-5.png" />
                      </div>
                      <div className="__info__ontop__left">
                        <p>
                          <img src="/assets/images/plane.svg" />
                          Flights To Accra
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                  <a href="">
                    <div className="__img__card __ontop__leftEl">
                      <div className="__card__img">
                        <img src="/assets/images/thumb-5.png" />
                      </div>
                      <div className="__info__ontop__left">
                        <p>
                          <img src="/assets/images/plane.svg" />
                          Flights To Accra
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                  <a href="">
                    <div className="__img__card __ontop__leftEl">
                      <div className="__card__img">
                        <img src="/assets/images/thumb-5.png" />
                      </div>
                      <div className="__info__ontop__left">
                        <p>
                          <img src="/assets/images/plane.svg" />
                          Flights To Accra
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                  <a href="">
                    <div className="__img__card __ontop__leftEl">
                      <div className="__card__img">
                        <img src="/assets/images/thumb-5.png" />
                      </div>
                      <div className="__info__ontop__left">
                        <p>
                          <img src="/assets/images/plane.svg" />
                          Flights To Accra
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                  <a href="">
                    <div className="__img__card __ontop__leftEl">
                      <div className="__card__img">
                        <img src="/assets/images/thumb-5.png" />
                      </div>
                      <div className="__info__ontop__left">
                        <p>
                          <img src="/assets/images/plane.svg" />
                          Flights To Accra
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section> */}
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default HomePage;
