import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Context, useGlobalContext } from "../context/Context";

const Navigation =()=> {
  const {googleUser} = useGlobalContext(Context);
  const baseURL = `https://admin.buyairticket.com.bd`;
    const currentUser = JSON.parse(sessionStorage.getItem("user"));
    const isLogin = localStorage.getItem("isLogin")
    const Image = currentUser?.ProfileImage;
    const name = currentUser?.FirstName;
    const Name = currentUser?.fName;
    // const Fname = Name[0];
    const title = currentUser?.Title;
    const photo = googleUser?.photo;
    const profileImageStyle={
      height: "30px",
      width: "30px",
      borderRadius: "50%",
      marginLeft:"10px"
    }
  // console.log(googleUser);
    return (
      <React.Fragment>
        <div className="__navigation">
          <div className="container container-xl container-xxl">
            <div className="__navigation__wrap">
              <div className="__navigation__website__logo">
                <button className="__humberger__menu">
                  <svg
                    fill="#000000"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24px"
                    height="24px"
                  >
                    <path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z" />
                  </svg>
                </button>
                <Link to="/"
                  onClick={
                    localStorage.setItem("blogClick", false)
                  }
                >
                  <img src="/assets/images/website-logo.png" alt="buyAirTicket" />
                </Link>
              </div>
              <div className="__navigation__mainEl">
                <div className="__navigation__topInfo">
                  <div className="__navigation__search">
                    <div className="input-group input_w_icon">
                      <span className="input-group-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                        >
                          <g
                            id="Group_299"
                            dataname="Group 299"
                            transform="translate(-2145.907 -4679)"
                          >
                            <path
                              id="Path_298"
                              dataname="Path 298"
                              d="M2145.907,4682.693c.033-.2.049-.407.1-.6a3.847,3.847,0,0,1,3.052-3,3.926,3.926,0,0,1,4.687,3.113,3.858,3.858,0,0,1-.725,3.134l-.064.086a.288.288,0,0,1-.03.042.531.531,0,0,1,.1.064q1.382,1.378,2.761,2.76c.16.16.163.25,0,.408-.106.1-.22.2-.33.3h-.118c-.056-.048-.115-.094-.167-.146q-1.36-1.359-2.72-2.72a.781.781,0,0,1-.078-.111l-.089.064a3.893,3.893,0,0,1-3.04.78,3.954,3.954,0,0,1-3.237-3.033c-.045-.2-.067-.409-.1-.614Zm7.083.266a3.124,3.124,0,1,0-3.122,3.127A3.128,3.128,0,0,0,2152.99,4682.959Z"
                            />
                          </g>
                        </svg>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                  <div className="__navigation__sl__wrap">
                    <ul className="__navigation__social__link">
                      <li>
                        <a href="https://www.facebook.com/buyairticketltd" target="_blank">
                          <img src="/assets/images/facebook-icon-2.svg" alt="facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/buyairticket/" target="_blank">
                          <img height="30px" width="30px" src="/assets/images/instagram-icon-2.svg" alt="instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/channel/UCAF7Xq8kjlOgBjU62LYHrkg" target="_blank">
                          <img height="30px" width="30px" src="/assets/images/youtube-icon-2.svg" alt="youtube" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/buyairticket/" target="_blank">
                          <img src="/assets/images/linkin-icon-2.svg" alt="linkedIn" />
                        </a>
                      </li>
                    </ul>
                    <div className="__navigation__location">
                      <img src="/assets/images/location-icon.svg" alt="location" />
                      BD
                    </div>
                    <div className="">
                      {isLogin? (<div className="__navigation__location">
                        {Image? <img src={`${baseURL}/img/profile/${Image}`} alt="profile" style={profileImageStyle} />:
                          photo? <img src={photo}  style={profileImageStyle} />:
                          <img src="/assets/images/user-icon.svg" alt="userIcon" />
                        }
                        {name || Name }
                      </div>)
                      :(<div className="__nav__login">
                        <Link to="/login">Login</Link>
                      </div>)}
                    </div>
                  </div>
                </div>
                <div className="__navigation__bottomInfo">
                  <ul className="__navigation__links">
                    <li>
                      <a href="">
                        <span>INFORMATION</span>
                        <img src="/assets/images/bottom-arrow-.svg" alt="information" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span>NEWS</span>
                        <img src="/assets/images/bottom-arrow-.svg" alt="news" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span>TRAVEL</span>
                        <img src="/assets/images/bottom-arrow-.svg" alt="travel"/>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span>CORONAVIRUS</span>
                        <img src="/assets/images/bottom-arrow-.svg" alt="coronavirus" />
                      </a>
                    </li>
                  </ul>
                  <div className="__navigation__account__login">
                    {currentUser? <Link to="/my-account">My Account</Link> : <Link to="/login">My Account</Link>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default Navigation;
