import React, { Component } from "react";
import Navigation from "../../components/navigation";
import Footer from "../../components/footer";

class Booking extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navigation />
        <div className="__page__wrap spacing_l">
          <div className="container container-lg container-xl container-xxl">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                <div className="__boxes__withTitle __flight__details">
                  <div className="__box__title">Flight Details</div>
                  <div className="__box__content">
                    <div className="__flight__detail__lists">
                      <div className="__list">
                        <div className="__list__badge">Onward</div>
                        <div className="__column">
                          <p className="__column__top">Etihad Airways</p>
                          <p className="__column__mid">EY-235</p>
                          <p className="__column__btm">Economy</p>
                        </div>
                        <div className="__column">
                          <p className="__column__top">Etihad Airways</p>
                          <p className="__column__mid">EY-235</p>
                          <p className="__column__btm">Economy</p>
                        </div>
                        <div className="__column __dif__column">
                          <p className="__column__top">1 Stop</p>
                          <p className="__column__mid">
                            <span className="__column__mid__time">
                              <img src="/assets/images/clock-small.svg" />
                              21 h 55 m
                            </span>
                            <span className="__column__mid__other">2C</span>
                          </p>
                          <p className="__column__btm">P/Person</p>
                        </div>
                        <div className="__column">
                          <p className="__column__top">JFK</p>
                          <p className="__column__mid">15:35</p>
                          <p className="__column__btm">Tue,20 Jul</p>
                        </div>
                      </div>
                      <div className="__list">
                        <div className="__list__badge">Return</div>
                        <div className="__column">
                          <p className="__column__top">Etihad Airways</p>
                          <p className="__column__mid">EY-235</p>
                          <p className="__column__btm">Economy</p>
                        </div>
                        <div className="__column">
                          <p className="__column__top">Etihad Airways</p>
                          <p className="__column__mid">EY-235</p>
                          <p className="__column__btm">Economy</p>
                        </div>
                        <div className="__column __dif__column">
                          <p className="__column__top">1 Stop</p>
                          <p className="__column__mid">
                            <span className="__column__mid__time">
                              <img src="/assets/images/clock-small.svg" />
                              21 h 55 m
                            </span>
                            <span className="__column__mid__other">2C</span>
                          </p>
                          <p className="__column__btm">P/Person</p>
                        </div>
                        <div className="__column">
                          <p className="__column__top">JFK</p>
                          <p className="__column__mid">15:35</p>
                          <p className="__column__btm">Tue,20 Jul</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="__boxes__withTitle __have__account">
                  <div className="__box__content">
                    <div className="__content__l">
                      <h6>Already have an account ?</h6>
                      <p>Login and checkout faster</p>
                    </div>
                    <div className="__content__r">
                      <a href="">LOGIN TO MY ACCOUNT</a>
                    </div>
                  </div>
                </div>

                <div className="__boxes__withTitle __email__detail">
                  <div className="__box__title">Email Details</div>
                  <div className="__box__content">
                    <div className="__info__lists __info__lists__wInput">
                      <div className="__info__list">
                        <div className="__info__list__label">
                          Your Email address:
                        </div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">
                          Enter Phone number:
                        </div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Phone number:"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">
                          Enter your Password
                        </div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Password"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label"></div>
                        <div className="__info__list__option">
                          <div className="select_checkbox">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                I have Password
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label"></div>
                        <div className="__info__list__option">
                          <div className="__submit__cta">
                            <button className="__save">CONTINUE</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="__boxes__withTitle __coupon__apply">
                  <div className="__box__content">
                    <div className="form-group input_t_text">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Have a Coupon ?"
                      />
                    </div>
                    <div className="__submit__btn">
                      <button className="__save">Apply</button>
                    </div>
                  </div>
                </div>

                <div className="__boxes__withTitle __traveller__list">
                  <div className="__box__content">
                    <div className="form-group select_t_default">
                      <label htmlFor="select_t_default">
                        <strong>Select from “Traveller List”</strong>
                      </label>
                      <div className="custom_select_wrap">
                        <select className="form-control" id="select_t_default">
                          <option></option>
                          <option>3 Days</option>
                          <option>7 Days</option>
                        </select>
                        <span className="custom_arrow"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="__boxes__withTitle __pinfo__list">
                  <div className="__box__content">
                    <div className="__content__normal">
                      <h6 className="__content__title">
                        Adult 1: <span>Above 12 Years</span>
                      </h6>
                      <div className="row">
                        <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                          <div className="form-group select_t_default">
                            <label htmlFor="select_t_default">Title:</label>
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>Mr</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                          <div className="form-group input_t_textWLabel">
                            <label htmlFor="email">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                          <div className="form-group input_t_textWLabel">
                            <label htmlFor="email">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                          <div className="form-group input_t_textWLabel">
                            <label htmlFor="email">Date of Birth</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Date of Birth"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="__content__colored">
                      <p className="__optional__text">Optional (Passport)</p>
                      <h6 className="__content__title">Passport Details:</h6>
                      <div className="row">
                        <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                          <div className="form-group input_t_textWLabel">
                            <label htmlFor="email">Passport No:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Passport No:"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                          <div className="form-group select_t_default">
                            <label htmlFor="select_t_default">
                              Nationality:
                            </label>
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>Select Nationality</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                          <div className="form-group select_t_default">
                            <label htmlFor="select_t_default">
                              Issuing Country:
                            </label>
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>Select Country</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                          <div className="form-group input_t_textWLabel">
                            <label htmlFor="email">Date of Birth</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Date of Birth"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="__boxes__withTitle __contact__info">
                  <div className="__box__content">
                    <div className="__content__normal">
                      <h6 className="__content__title">
                        Your Contact Information
                      </h6>
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="form-group input_t_textWLabel">
                            <label htmlFor="email">Name: *</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="form-group input_t_textWLabel">
                            <label htmlFor="email">Email Address: *</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                          <div className="form-group select_t_default">
                            <label htmlFor="select_t_default">
                              Country Code: *
                            </label>
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>Bangladesh(+880)</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                          <div className="form-group input_t_textWLabel">
                            <label htmlFor="email">Phone Number: *</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Phone Number"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="form-group input_t_textWLabel">
                            <label htmlFor="email">
                              Confirm Email Address: *
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Confirm Email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="__collapse__box">
                  <div className="tab">
                    <input
                      className="tab__input"
                      id="tab-120"
                      type="checkbox"
                    />
                    <label className="tab__label" htmlFor="tab-120">
                      <span>Hot Deals</span>
                    </label>
                    <div className="tab__content">
                      <div className="content__wrap">
                        <div className="__coupon__content">
                          <div className="__coupon__content__success">
                            <img src="/assets/images/cc.svg" alt="" />
                          </div>
                          <h4>
                            <img src="/assets/images/ss.svg" alt="" />
                            DOMFLY410
                          </h4>
                          <h6>
                            8% discount for BARC, City AMEX, Dhaka Bank, EBL,
                            LankaBangla, Standard Chartered UCB cards &amp;
                            bKash payment.
                          </h6>
                          <a href="">Terms &amp; Conditions</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="__collapse__box">
                  <div className="tab">
                    <input
                      className="tab__input"
                      id="tab-130"
                      type="checkbox"
                    />
                    <label className="tab__label" htmlFor="tab-130">
                      <span>Cancellation Protection</span>
                    </label>
                    <div className="tab__content">
                      <div className="content__wrap">
                        <div className="__protection">
                          <div className="__badge">
                            Choose Cancellation Protection and receive a full
                            refund (<strong>৳ 72,078.72</strong>) if you cannot
                            fly
                          </div>
                          <div className="__protection__lists">
                            <li className="__protection__yes active">
                              <div className="__p__title">
                                <div className="__p__price">৳ 72,078.72</div>
                                <img src="/assets/images/syes.svg" alt="" />
                                <img src="/assets/images/yes.svg" alt="" />
                                Yes, protect my booking
                              </div>
                              <ul>
                                <li>Full refund: ৳ 72,078.72</li>
                                <li>Immediate relative sickness</li>
                                <li>Illness</li>
                                <li>Home emergency</li>
                                <li>Sickness, Accident and Injury</li>
                                <li>Adverse weather</li>
                                <li>Adverse weather</li>
                                <li>Adverse weather</li>
                                <li>Adverse weather</li>
                                <li>Adverse weather</li>
                                <li>Adverse weather</li>
                                <li>Adverse weather</li>
                              </ul>
                            </li>
                            <li className="__protection__no">
                              <div className="__p__title">
                                <img src="/assets/images/nno.svg" alt="" />
                                <img src="/assets/images/no.svg" alt="" />
                                Yes, protect my booking
                              </div>
                            </li>
                          </div>
                          <a href="">
                            Cancellation Protection Terms &amp; Conditions
                            listed here.
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="__collapse__box">
                  <div className="tab">
                    <input
                      className="tab__input"
                      id="tab-140"
                      type="checkbox"
                    />
                    <label className="tab__label" htmlFor="tab-140">
                      <span>Lost Baggage Protection</span>
                    </label>
                    <div className="tab__content">
                      <div className="content__wrap">
                        <div className="__protection">
                          <div className="__badge">
                            Choose Cancellation Protection and receive a full
                            refund (<strong>৳ 72,078.72</strong>) if you cannot
                            fly
                          </div>
                          <div className="__protection__lists">
                            <li className="__protection__yes __l__protection active">
                              <div className="__p__title">
                                <div className="__p__price">৳ 72,078.72</div>
                                <img src="/assets/images/syes.svg" alt="" />
                                <img src="/assets/images/yes.svg" alt="" />
                                Yes, protect my baggage
                              </div>
                              <ul>
                                <li>
                                  Get USD $1000 compensation if your baggage is
                                  not found within 4 days (96 hours).
                                </li>
                                <li>
                                  Get real time update by SMS & email on your
                                  baggage tracking.
                                </li>
                                <li>
                                  We don’t need to know your baggage contets.
                                </li>
                                <li>
                                  Applied for all checked baggage, including
                                  last minute gate checks.
                                </li>
                              </ul>
                            </li>
                            <li className="__protection__no">
                              <div className="__p__title">
                                <img src="/assets/images/nno.svg" alt="" />
                                <img src="/assets/images/no.svg" alt="" />
                                No, I will risk my baggage
                              </div>
                            </li>
                          </div>
                          <a href="">
                            Cancellation Protection Terms &amp; Conditions
                            listed here.
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="__collapse__box">
                  <div className="tab">
                    <input
                      className="tab__input"
                      id="tab-150"
                      type="checkbox"
                    />
                    <label className="tab__label" htmlFor="tab-150">
                      <span>Seat Selection</span>
                    </label>
                    <div className="tab__content">
                      <div className="content__wrap p_t_30">
                        <div className="__setselection">
                          <div className="__top__price">
                            From <span>৳ 2,521.00</span>
                          </div>
                          <div className="__badge">
                            Chosse your seat selection or{" "}
                            <a href="">open the seat map</a>
                          </div>
                          <div className="__select__set">
                            <div className="__set __set__left">
                              <div className="__top"></div>
                              <div className="__middle">
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <div className="__bottom">Window Seat</div>
                            </div>
                            <div className="__set __set__middle">
                              <div className="__top"></div>
                              <div className="__middle">
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <div className="__bottom">Middle Seat</div>
                            </div>
                            <div className="__set __set__right">
                              <div className="__top"></div>
                              <div className="__middle">
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <div className="__bottom">Aisel Seat</div>
                            </div>
                          </div>

                          <div className="__cfooter">
                            <img src="/assets/images/set.svg" alt="" />
                            <span>
                              Open the Seat Map to chosse your exact seat{" "}
                            </span>
                            <a href="">View Seat Map</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="__collapse__box">
                  <div className="tab">
                    <input
                      className="tab__input"
                      id="tab-160"
                      type="checkbox"
                    />
                    <label className="tab__label" htmlFor="tab-160">
                      <span>Select Meal Preferences</span>
                    </label>
                    <div className="tab__content">
                      <div className="content__wrap">
                        <div className="__meal__preference">
                          <div className="__top__price">
                            From <span>৳ 2,521.00</span>
                          </div>

                          <div className="__what">
                            <div className="__adult">Adults 1</div>
                            <div className="__plater">
                              <div className="__plater__top">
                                <span>CCU</span>
                                <span>
                                  <img src="/assets/images/plane.svg" alt="" />
                                </span>
                                <span>BLR</span>
                              </div>
                              <div className="__plater__bottom">
                                <div className="form-group select_t_default">
                                  <div className="custom_select_wrap">
                                    <select
                                      className="form-control"
                                      id="select_t_default"
                                    >
                                      <option>FRUIT PLATTER - (0.0B)</option>
                                      <option>3 Days</option>
                                      <option>7 Days</option>
                                    </select>
                                    <span className="custom_arrow"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="__collapse__box">
                  <div className="tab">
                    <input
                      className="tab__input"
                      id="tab-170"
                      type="checkbox"
                    />
                    <label className="tab__label" htmlFor="tab-170">
                      <span>Covid Test</span>
                    </label>
                    <div className="tab__content">
                      <div className="content__wrap">
                        <div className="__covid__test">
                          <div className="__badge">
                            RT-PCR test from Govt. approved labs is mandatory
                            for all international travels.
                          </div>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-6">
                              <div className="__box__list">
                                <div className="__box__list__title">
                                  Praava Health
                                </div>
                                <div className="__box__list__body">
                                  <ul>
                                    <li>Lab Collection at Banani, Dhaka</li>
                                  </ul>
                                </div>
                                <div className="__box__list__footer">
                                  <a href="">Learn More</a>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                              <div className="__box__list">
                                <div className="__box__list__title">
                                  Stemz Healthcare (BD)
                                </div>
                                <div className="__box__list__body">
                                  <ul>
                                    <li>Home Collection only Dhaka City</li>
                                    <li>
                                      Lab Collection at Banglamotor, Dhaka
                                    </li>
                                  </ul>
                                </div>
                                <div className="__box__list__footer">
                                  <a href="">Learn More</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="__r__price">
                            <div>BDT 0</div>
                            <span>total for 1 traveler</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="__collapse__box">
                  <div className="tab">
                    <input
                      className="tab__input"
                      id="tab-180"
                      type="checkbox"
                    />
                    <label className="tab__label" htmlFor="tab-180">
                      <span>Passport Update</span>
                    </label>
                    <div className="tab__content">
                      <div className="content__wrap">
                        <div className="__passport__update">
                          <div className="__badge">
                            Sign in to upload Photo of Passport
                          </div>

                          <div className="__upload__phot">
                            <div className="__file__inputs">
                              <label for="formFile" class="form-label">
                                <img src="/assets/images/file.svg" alt="" />
                              </label>
                              <input
                                class="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                            <p className="__utext">
                              Upload a photo of the front page of your passport
                            </p>
                            <a className="__view__sample" href="">
                              View Sample
                            </a>
                            <p className="__utext">JPG/JPGE/PNG</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*<div className="__collapse__box">
                  <div className="tab">
                    <input
                      className="tab__input"
                      id="tab-190"
                      type="checkbox"
                    />
                    <label className="tab__label" htmlFor="tab-190">
                      <span>Payment</span>
                    </label>
                    <div className="tab__content">
                      <div className="content__wrap">hehe</div>
                    </div>
                  </div>
                </div>
               */}
              </div>

              <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="__widgets__time">
                  <div className="__widgets__time__wrap">
                    <img src="/assets/images/clock-icon.svg" />
                    <div className="__min">
                      <span className="__min__val">29</span>
                      <span className="__min__title">min</span>
                    </div>
                    <div className="__colon">:</div>
                    <div className="__sec">
                      <span className="__sec__val">45</span>
                      <span className="__sec__title">sec</span>
                    </div>
                  </div>
                </div>

                <div className="__widgets__help">
                  <h4 className="__widgets__help__title">Need Help ?</h4>
                  <ul className="__widgets__helpline__list">
                    <li>
                      <img src="/assets/images/phone.svg" />
                      <span>+88 00000000000</span>
                    </li>
                    <li>
                      <img src="/assets/images/messenger.svg" />
                      <span>Link</span>
                    </li>
                  </ul>
                </div>

                <div className="__widgets__detail __widgets__flight">
                  <h4 className="__widgets__detail__title">
                    <img src="/assets/images/plane-icon.svg" />
                    <span>Flight details</span>
                  </h4>
                  <div className="__widgets__detail__lists">
                    <div className="__detail__list">
                      <div className="__list__l">Fri 23/07/2021</div>
                      <div className="__list__r">Etihad Airways</div>
                    </div>
                    <div className="__detail__list">
                      <div className="__list__l">Dhaka (DAC)</div>
                      <div className="__list__r">03:40</div>
                    </div>
                    <div className="__detail__list">
                      <div className="__list__l">Toronto (YYZ)</div>
                      <div className="__list__r">03:40</div>
                    </div>
                    <div className="__detail__list">
                      <div className="__list__l">1 stops(s)</div>
                      <div className="__list__r"></div>
                    </div>
                  </div>
                </div>

                <div className="__widgets__detail">
                  <h4 className="__widgets__detail__title">
                    <img src="/assets/images/passengers-icon.svg" />
                    <span>Passengers</span>
                  </h4>
                  <div className="__widgets__detail__lists">
                    <div className="__detail__list">
                      <div className="__list__l">Adults:</div>
                      <div className="__list__r">1</div>
                    </div>
                    <div className="__detail__list">
                      <div className="__list__l">Children:</div>
                      <div className="__list__r">1</div>
                    </div>
                    <div className="__detail__list">
                      <div className="__list__l">Infants:</div>
                      <div className="__list__r">0</div>
                    </div>
                    <div className="__detail__list __detail__list__total">
                      <div className="__list__l">Total Passengers:</div>
                      <div className="__list__r">2</div>
                    </div>
                  </div>
                </div>

                <div className="__widgets__detail __widgets__payment">
                  <h4 className="__widgets__detail__title">
                    <img src="/assets/images/payment-icon.svg" />
                    <span>Payment</span>
                  </h4>
                  <div className="__widgets__detail__lists">
                    <div className="__detail__list">
                      <div className="__list__l">Fare (inc Tax):</div>
                      <div className="__list__r">72,078.72</div>
                    </div>
                    <div className="__detail__list">
                      <div className="__list__l">Payment surcharge:</div>
                      <div className="__list__r">0.00</div>
                    </div>
                    <div className="__detail__list">
                      <div className="__list__l">Passengers:</div>
                      <div className="__list__r">x1</div>
                    </div>
                    <div className="__detail__list">
                      <div className="__list__l">
                        Hot Deals:{" "}
                        <span className="__promocode">DOMFLY0410</span>
                      </div>
                      <div className="__list__r">- BDT 281</div>
                    </div>
                    <div className="__detail__list __detail__list__total">
                      <div className="__list__l">Total:</div>
                      <div className="__list__r">৳ 72,078.72</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Booking;
