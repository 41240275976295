import React, { Component } from "react";

class ModalComponent extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="__page__wrap spacing_l">
          <div className="container container-lg container-xl container-xxl">
            <h1>My Account Modal</h1>
            <div className="row">
              <div className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 mx-auto">
                <div className="__modal __my__account">
                  <div className="__modal__head">
                    <div className="__modal__close">
                      <button>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.672"
                          height="13.109"
                          viewBox="0 0 12.672 13.109"
                        >
                          <g
                            id="Group_149"
                            dataName="Group 149"
                            transform="translate(-1294.369 -59.891)"
                          >
                            <path
                              id="Path_139"
                              dataName="Path 139"
                              d="M1299.257,66.645l-4.888-5.426,1.7-1.328,4.636,5.146,4.636-5.146,1.7,1.328-4.888,5.426,4.528,5.026-1.7,1.329-4.276-4.747L1296.429,73l-1.7-1.329Z"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div className="__modal__title">MY ACCOUNT</div>
                  </div>
                  <div className="__modal__body">
                    <div className="__top__focus">
                      <div className="__item">
                        <div className="__item__img">
                          <img src="/assets/images/rectangle22.png" />
                        </div>
                        <div className="__item__title">
                          <span>Login</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9.697"
                            height="16.956"
                            viewBox="0 0 9.697 16.956"
                          >
                            <g
                              id="Group_164"
                              dataName="Group 164"
                              transform="translate(-976.738 -263.202)"
                            >
                              <g id="Group_163" dataName="Group 163">
                                <path
                                  id="Path_148"
                                  dataName="Path 148"
                                  d="M983.248,271.741c-.834-.869-1.594-1.659-2.353-2.451q-1.979-2.06-3.956-4.121a.454.454,0,0,1,0-.749q.5-.516,1-1.029a.429.429,0,0,1,.714,0q3.807,3.963,7.611,7.929a.458.458,0,0,1,0,.715q-3.812,3.972-7.625,7.941a.42.42,0,0,1-.686,0c-.328-.332-.652-.668-.978-1-.311-.318-.315-.5-.01-.817q2.77-2.886,5.545-5.77C982.731,272.16,982.991,271.966,983.248,271.741Z"
                                  fill="gray"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div className="__item">
                        <div className="__item__img">
                          <img src="/assets/images/rectangle22.png" />
                        </div>
                        <div className="__item__title">
                          <span>Traveller</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9.697"
                            height="16.956"
                            viewBox="0 0 9.697 16.956"
                          >
                            <g
                              id="Group_164"
                              dataName="Group 164"
                              transform="translate(-976.738 -263.202)"
                            >
                              <g id="Group_163" dataName="Group 163">
                                <path
                                  id="Path_148"
                                  dataName="Path 148"
                                  d="M983.248,271.741c-.834-.869-1.594-1.659-2.353-2.451q-1.979-2.06-3.956-4.121a.454.454,0,0,1,0-.749q.5-.516,1-1.029a.429.429,0,0,1,.714,0q3.807,3.963,7.611,7.929a.458.458,0,0,1,0,.715q-3.812,3.972-7.625,7.941a.42.42,0,0,1-.686,0c-.328-.332-.652-.668-.978-1-.311-.318-.315-.5-.01-.817q2.77-2.886,5.545-5.77C982.731,272.16,982.991,271.966,983.248,271.741Z"
                                  fill="gray"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div className="__item">
                        <div className="__item__img">
                          <img src="/assets/images/rectangle22.png" />
                        </div>
                        <div className="__item__title">
                          <span>Travel Agent</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9.697"
                            height="16.956"
                            viewBox="0 0 9.697 16.956"
                          >
                            <g
                              id="Group_164"
                              dataName="Group 164"
                              transform="translate(-976.738 -263.202)"
                            >
                              <g id="Group_163" dataName="Group 163">
                                <path
                                  id="Path_148"
                                  dataName="Path 148"
                                  d="M983.248,271.741c-.834-.869-1.594-1.659-2.353-2.451q-1.979-2.06-3.956-4.121a.454.454,0,0,1,0-.749q.5-.516,1-1.029a.429.429,0,0,1,.714,0q3.807,3.963,7.611,7.929a.458.458,0,0,1,0,.715q-3.812,3.972-7.625,7.941a.42.42,0,0,1-.686,0c-.328-.332-.652-.668-.978-1-.311-.318-.315-.5-.01-.817q2.77-2.886,5.545-5.77C982.731,272.16,982.991,271.966,983.248,271.741Z"
                                  fill="gray"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="__p__guide">
                      <div className="__modal__sub__title">Passenger guide</div>

                      <div className="__collapse__box__modal__wrap">
                        <div className="__collapse__box__modal">
                          <div className="tab">
                            <input
                              className="tab__input"
                              id="tab-220"
                              type="checkbox"
                            />
                            <label className="tab__label" htmlFor="tab-220">
                              <span>
                                <img
                                  src="/assets/images/help-icon.svg"
                                  alt=""
                                />{" "}
                                Manage Booking
                              </span>
                            </label>
                            <div className="tab__content">
                              <div className="content__wrap">
                                <div className="__t__s_form">
                                  <p>
                                    Add trip details to start earning money.
                                  </p>
                                  <div className="select__option">
                                    <div className="radio_select">
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input custom_form_check_input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                        />
                                        <label
                                          className="form-check-label custom_form_check_label"
                                          htmlFor="flexRadioDefault1"
                                        >
                                          Round trip
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input custom_form_check_input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault2"
                                        />
                                        <label
                                          className="form-check-label custom_form_check_label"
                                          htmlFor="flexRadioDefault2"
                                        >
                                          One way
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group input_t_text">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          placeholder="Traveling from"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group input_t_text">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          placeholder="Traveling to"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group input_t_text">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          placeholder="Arrival date"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group input_t_text">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          placeholder="Return date"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="__submit__cta centerEl">
                                        <button className="__save">
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="__collapse__box__modal">
                          <div className="tab">
                            <input
                              className="tab__input"
                              id="tab-230"
                              type="checkbox"
                            />
                            <label className="tab__label" htmlFor="tab-230">
                              <span>
                                <img
                                  src="/assets/images/plus-icon.svg"
                                  alt=""
                                />{" "}
                                Find Booking
                              </span>
                            </label>
                            <div className="tab__content">
                              <div className="content__wrap">
                                <div className="__t__s_form">
                                  <p>
                                    Add trip details to start earning money.
                                  </p>
                                  <div className="select__option">
                                    <div className="radio_select">
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input custom_form_check_input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                        />
                                        <label
                                          className="form-check-label custom_form_check_label"
                                          htmlFor="flexRadioDefault1"
                                        >
                                          Round trip
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input custom_form_check_input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault2"
                                        />
                                        <label
                                          className="form-check-label custom_form_check_label"
                                          htmlFor="flexRadioDefault2"
                                        >
                                          One way
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group input_t_text">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          placeholder="Traveling from"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group input_t_text">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          placeholder="Traveling to"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group input_t_text">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          placeholder="Arrival date"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group input_t_text">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          placeholder="Return date"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="__submit__cta centerEl">
                                        <button className="__save">
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="__collapse__box__modal">
                          <div className="tab">
                            <input
                              className="tab__input"
                              id="tab-240"
                              type="checkbox"
                            />
                            <label className="tab__label" htmlFor="tab-240">
                              <span>
                                <img
                                  src="/assets/images/star-icon.svg"
                                  alt=""
                                />{" "}
                                Add Trip
                              </span>
                            </label>
                            <div className="tab__content">
                              <div className="content__wrap">
                                <div className="__t__s_form">
                                  <p>
                                    Add trip details to start earning money.
                                  </p>
                                  <div className="select__option">
                                    <div className="radio_select">
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input custom_form_check_input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                        />
                                        <label
                                          className="form-check-label custom_form_check_label"
                                          htmlFor="flexRadioDefault1"
                                        >
                                          Round trip
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input custom_form_check_input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault2"
                                        />
                                        <label
                                          className="form-check-label custom_form_check_label"
                                          htmlFor="flexRadioDefault2"
                                        >
                                          One way
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group input_t_text">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          placeholder="Traveling from"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group input_t_text">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          placeholder="Traveling to"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group input_t_text">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          placeholder="Arrival date"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group input_t_text">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          placeholder="Return date"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="__submit__cta centerEl">
                                        <button className="__save">
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <br />
                <div className="__modal __modal__fpassword">
                  <div className="__modal__head">
                    <div className="__modal__close">
                      <button>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.671"
                          height="13.109"
                          viewBox="0 0 12.671 13.109"
                        >
                          <g
                            id="Group_45"
                            dataName="Group 45"
                            transform="translate(717.938 -466.682)"
                          >
                            <path
                              id="Path_5"
                              dataName="Path 5"
                              d="M-713.051,473.436l-4.887-5.426,1.7-1.328,4.636,5.146,4.637-5.146,1.7,1.328-4.887,5.426,4.527,5.027-1.7,1.328-4.277-4.747-4.276,4.747-1.7-1.328Z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div className="__modal__title">
                      Can’t sign in? Forgot Password?
                    </div>
                  </div>
                  <div className="__modal__body">
                    <div className="__inputs">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="email">User ID</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Supportbd"
                        />
                      </div>
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="support@buyairticket.com"
                        />
                      </div>
                      <div className="CTA__wrap">
                        <button className="__submit">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <br />
                <div className="__modal __modal__fpassword">
                  <div className="__modal__head">
                    <div className="__modal__close">
                      <button>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.671"
                          height="13.109"
                          viewBox="0 0 12.671 13.109"
                        >
                          <g
                            id="Group_45"
                            dataName="Group 45"
                            transform="translate(717.938 -466.682)"
                          >
                            <path
                              id="Path_5"
                              dataName="Path 5"
                              d="M-713.051,473.436l-4.887-5.426,1.7-1.328,4.636,5.146,4.637-5.146,1.7,1.328-4.887,5.426,4.527,5.027-1.7,1.328-4.277-4.747-4.276,4.747-1.7-1.328Z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div className="__modal__title">
                      Can’t sign in? Forgot Password?
                    </div>
                  </div>
                  <div className="__modal__body">
                    <div className="__inputs">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="email">User ID</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Supportbd"
                        />
                      </div>
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="support@buyairticket.com"
                        />
                      </div>
                      <div className="__note">
                        One Time Password (OTP) emailed to you.
                      </div>

                      <div className="__otp__input">
                        <div className="form-group input_t_textWLabel">
                          <label htmlFor="email">
                            Enter OTP (One Time Password)
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder=""
                          />
                        </div>
                        <span className="__otp__input__ltime">
                          OTP Valid - 4:55
                        </span>
                      </div>

                      <div className="CTA__wrap">
                        <button className="__submit">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ModalComponent;
