import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';

// Bootstrap@5.0.3
import 'bootstrap/dist/css/bootstrap.min.css';
import { ContextProvider } from './context/Context';
import SchemaMarkup from './SchemaMarkup';

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
