import React, { useEffect, useState } from 'react'

const FaqList = ({BlogId}) => {
    // console.log(BlogId);
    const baseUrl = 'https://admin.buyairticket.com.bd'
    const [data, setData] = useState([]);
    const fetchUrl = ()=>{
      BlogId && fetch(`${baseUrl}/api/blog/getblogdetailsbyid/${BlogId}`)
      .then(res => res.json())
      .then(blogData => {
        const faq = blogData.FqaList
        console.log(faq);
        setData(faq);
      })
    }
    useEffect(()=>{
        fetchUrl()
      },[BlogId])
    return (
        <>
            <section className="__flight__faq">
                <h5 className="__flight__faq__title">Flight FAQ</h5>
                <div className="faq__css__collapse">
                { data.map(faq => {
                    return(
                
                <div className="tab">
                    <input
                    className="tab__input"
                    // id="tab-1"
                    id ={faq.QuestionId}
                    type="checkbox"
                    />
                    <label className="tab__label" htmlFor={faq.QuestionId}>
                    <span>
                        {faq.Question}
                    </span>
                    </label>
                    <div className="tab__content">
                    <div className="content__wrap">
                        <p>
                        {faq.Answer}
                        </p>
                    </div>
                    </div>
                </div>)})
                }
                </div>
            </section>
        </>
    )
}

export default FaqList
