import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }}
      onClick={onClick}
    >
      <img src="/assets/images/next-a.svg" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }}
      onClick={onClick}
    >
      <img src="/assets/images/after-a.svg" />
    </div>
  );
}

class Column3SliderConnect extends Component {
  state = {};
  render() {
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Slider {...settings}>
        <div>
          <div className="__vh__card">
            <div className="__card__img">
              <img src="/assets/images/thumb-2.png" />
            </div>
            <div className="__card__info">
              <h4 className="__info__title">China</h4>
              <div className="__info__time__wrap">
                <div className="__info__time">Arrive 01 Nov 2022</div>
                <div className="__info__time">Return 15 Nov 2022</div>
              </div>
              {/* <p className="__info__desc">
                    Tour the World from Your Couch with #ExperienceLive!
                  </p> */}
              <div className="__info__cta">
                <a href="https://skyflicker.com" target="_blank">Send Message</a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="__vh__card">
            <div className="__card__img">
              <img src="/assets/images/Thailang.png" />
            </div>
            <div className="__card__info">
              <h4 className="__info__title">Thailand</h4>
              <div className="__info__time__wrap">
                <div className="__info__time">Arrive 16 Dec 2021</div>
                <div className="__info__time">Return 25 Dec 2021</div>
              </div>
              {/* <p className="__info__desc">
                    Tour the World from Your Couch with #ExperienceLive!
                  </p> */}
              <div className="__info__cta">
                <a href="https://skyflicker.com" target="_blank">Send Message</a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="__vh__card">
            <div className="__card__img">
              <img src="/assets/images/singapur.png" />
            </div>
            <div className="__card__info">
              <h4 className="__info__title">Singapore</h4>
              <div className="__info__time__wrap">
                <div className="__info__time">Arrive 03 Mar 2022</div>
                <div className="__info__time">Return 23 Mar 2022</div>
              </div>
              {/* <p className="__info__desc">
                    Tour the World from Your Couch with #ExperienceLive!
                  </p> */}
              <div className="__info__cta">
                <a href="https://skyflicker.com" target="_blank">Send Message</a>
              </div>
            </div>
          </div>
        </div>
        
      </Slider>
    );
  }
}

export default Column3SliderConnect;
