const SmallNav = ({ top, bottom }) => {
  return (
    <div className="__small__nav">
      {top ? (
        <div className="__small__nav__top">
          <div className="container container-lg container-xl container-xxl">
            <ul>
              <li>My Trips</li>
            </ul>
          </div>
        </div>
      ) : null}

      {bottom ? (
        <div className="__small__nav__bottom">
          <div className="container container-lg container-xl container-xxl">
            <ul>
              <li>
                <a href="">My Account</a>
              </li>
              <li>
                <a href="">My Trips</a>
              </li>
              <li className="active">
                <a href="">My Wallet</a>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SmallNav;
