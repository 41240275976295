import React, { Component } from "react";
import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import SmallNav from "../../components/smallNav";
import SidebarNav from "../../components/sidebarNav";
import PersonalDetails from "./PersonalDetails";
import ChangePassword from "./ChangePassword";

const MyAccount =()=> {
    return (
      <React.Fragment>
        <Navigation />
        <SmallNav top={true} bottom={true} />
        <div className="__page__wrap spacing_l">
          <div className="container container-lg container-xl container-xxl">
            <div className="row">
              <div className="col-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <SidebarNav />
              </div>
              <div className="col-9 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                <div className="__wrap__boxes">
                  <div className="__add__money">
                    <a href="">Add Money</a>
                  </div>
                  <div className="__dashes__border"></div>
                  <div className="row">
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <div className="__main__info__box">
                        <div className="__main__info__box__title">
                          <h6>Total Balance</h6>
                        </div>
                        <div className="__main__info__box__body">
                          <h4>৳ BDT 0</h4>
                        </div>
                        <div className="__main__info__box__footer">
                          <a href="">View Transaction</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <div className="__main__info__box">
                        <div className="__main__info__box__title">
                          <h6>Your Cash</h6>
                        </div>
                        <div className="__main__info__box__body">
                          <h4>৳ BDT 0</h4>
                        </div>
                        <div className="__main__info__box__footer">
                          <a href="">View Transaction</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <div className="__main__info__box">
                        <div className="__main__info__box__title">
                          <h6>Offer Cash</h6>
                        </div>
                        <div className="__main__info__box__body">
                          <h4>৳ BDT 0</h4>
                        </div>
                        <div className="__main__info__box__footer">
                          <a href="">View Transaction</a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="__table__search__option">
                        <div className="__table__search__option__column">
                          <div className="form-group input_t_textWLabel">
                            <label htmlFor="email">From Date:</label>
                            <input
                              type=""
                              className="form-control"
                              id="email"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="__table__search__option__column">
                          <div className="form-group input_t_textWLabel">
                            <label htmlFor="email">To Date:</label>
                            <input
                              type=""
                              className="form-control"
                              id="email"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="__table__search__option__column">
                          <div className="form-group select_t_default">
                            <label htmlFor="select_t_default">
                              Transaction Type:
                            </label>
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>All</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                        <div className="__table__search__option__column">
                          <div className="form-group select_t_default">
                            <label htmlFor="select_t_default">
                              Transaction Mode:
                            </label>
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>All</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                        <div className="__table__search__option__column">
                          <div className="form-group select_t_default">
                            <label htmlFor="select_t_default">Service:</label>
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>All</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                        <div className="__table__search__option__column">
                          <div className="__btn__wrap">
                            <button className="__search">Search</button>
                          </div>
                        </div>
                        <div className="__table__search__option__column">
                          <div className="__btn__wrap">
                            <button className="__download">Download</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="__dashes__border"></div>
                  <div className="__info__table">
                    <div className="__info__table__head">
                      <div className="__info__table__head__column">
                        Trip Type
                      </div>
                      <div className="__info__table__head__column">Date</div>
                      <div className="__info__table__head__column">Time</div>
                      <div className="__info__table__head__column __info__table__large__column">
                        Transaction
                      </div>
                      <div className="__info__table__head__column">Amount</div>
                    </div>
                    <div className="__info__table__body">
                      <div className="__info__table__body__row">
                        <div className="__info__table__body__column">
                          Wallet
                        </div>
                        <div className="__info__table__body__column">
                          04/05/2021
                        </div>
                        <div className="__info__table__body__column">
                          13:34:37
                        </div>
                        <div className="__info__table__body__column __info__table__large__column">
                          Wallet Registraion!!
                        </div>
                        <div className="__info__table__body__column">
                          (+) BDT 0 <br />
                          Earned
                        </div>
                      </div>
                      <div className="__info__table__body__row">
                        <div className="__info__table__body__column">
                          Wallet
                        </div>
                        <div className="__info__table__body__column">
                          04/05/2021
                        </div>
                        <div className="__info__table__body__column">
                          13:34:37
                        </div>
                        <div className="__info__table__body__column __info__table__large__column">
                          Wallet Registraion!!
                        </div>
                        <div className="__info__table__body__column">
                          (+) BDT 0 <br />
                          Earned
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <PersonalDetails />
                <br />
                <br />
                <ChangePassword />
                <br />
                <br />
                <div className="__wrap__boxes">
                  <div className="__detail__info">
                    <div className="__title__withicon">
                      <div className="__title__icon">
                        <img src="/assets/images/edit-icon.svg" />
                      </div>
                      <h3 className="__title">Personal Information</h3>
                    </div>

                    <div className="__info__lists __info__lists__wInput">
                      <div className="__info__list">
                        <div className="__info__list__label">Type:</div>
                        <div className="__info__list__option">
                          <div className="form-group select_t_default">
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>Adult</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">Title:*</div>
                        <div className="__info__list__option">
                          <div className="form-group select_t_default">
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>Mr</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">First Name:*</div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">Middle Name:</div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">Last Name :*</div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">
                          Date Of Birth:*
                        </div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="m_t_40"></div>

                    <div className="__title__withicon">
                      <div className="__title__icon">
                        <img src="/assets/images/edit-icon.svg" />
                      </div>
                      <h3 className="__title">Preferences</h3>
                    </div>
                    <div className="__info__lists __info__lists__wInput">
                      <div className="__info__list">
                        <div className="__info__list__label">
                          Seat Preference:
                        </div>
                        <div className="__info__list__option">
                          <div className="form-group select_t_default">
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>No Preferences</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">
                          Meal Preference:
                        </div>
                        <div className="__info__list__option">
                          <div className="form-group select_t_default">
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>No Preferences</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">
                          Special Preference:
                        </div>
                        <div className="__info__list__option">
                          <div className="form-group select_t_default">
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>No Preferences</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="m_t_40"></div>

                    <div className="__title__withicon">
                      <div className="__title__icon">
                        <img src="/assets/images/edit-icon.svg" />
                      </div>
                      <h3 className="__title">Frequent Flyer Information</h3>
                    </div>
                    <div className="__info__lists __info__lists__wInput">
                      <div className="__info__list">
                        <div className="__info__list__label">
                          Frequent Flyer Name:
                        </div>
                        <div className="__info__list__option">
                          <div className="form-group select_t_default">
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>Select Airlines</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">
                          Frequent Flyer No:
                        </div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="m_t_40"></div>

                    <div className="__title__withicon">
                      <div className="__title__icon">
                        <img src="/assets/images/edit-icon.svg" />
                      </div>
                      <h3 className="__title">Passport Information</h3>
                    </div>
                    <div className="__info__lists __info__lists__wInput">
                      <div className="__info__list">
                        <div className="__info__list__label">Passport No :</div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">Nationality:</div>
                        <div className="__info__list__option">
                          <div className="form-group select_t_default">
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>Select Country</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">
                          Issuing Country:
                        </div>
                        <div className="__info__list__option">
                          <div className="form-group select_t_default">
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>Select Country</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">Issued Date:</div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">
                          Expiration Date:
                        </div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="__info__lists __info__lists__wInput m_t_30">
                      <div className="__info__list">
                        <div className="__info__list__label"></div>
                        <div className="__info__list__option">
                          <div className="__submit__cta rightEl">
                            <button className="__save">Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <br />
                <div className="__wrap__boxes">
                  <div className="__detail__info">
                    <div className="__title__withicon">
                      <div className="__title__icon">
                        <img src="/assets/images/edit-icon.svg" />
                      </div>
                      <h3 className="__title">Passport Information</h3>
                    </div>

                    <div className="__info__lists __info__lists__wInput">
                      <div className="__info__list">
                        <div className="__info__list__label">Title:</div>
                        <div className="__info__list__option">
                          <div className="form-group select_t_default">
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>Mr</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="__info__list">
                        <div className="__info__list__label">First Name:*</div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">Middle Name:*</div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">Last Name:*</div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">Email:*</div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">Address:*</div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">City:*</div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">Country:*:</div>
                        <div className="__info__list__option">
                          <div className="form-group select_t_default">
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>Bangladesh</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">State:*</div>
                        <div className="__info__list__option">
                          <div className="form-group select_t_default">
                            <div className="custom_select_wrap">
                              <select
                                className="form-control"
                                id="select_t_default"
                              >
                                <option>N/A - Not Applicable</option>
                                <option>3 Days</option>
                                <option>7 Days</option>
                              </select>
                              <span className="custom_arrow"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">Zip:*</div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="__info__list">
                        <div className="__info__list__label">
                          Mobile Phone:*
                        </div>
                        <div className="__info__list__option">
                          <div className="form-group input_t_text">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="__info__lists __info__lists__wInput m_t_30">
                      <div className="__info__list">
                        <div className="__info__list__label"></div>
                        <div className="__info__list__option">
                          <div className="__submit__cta rightEl">
                            <button className="__save">Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="__promo__code">
                  <div className="__wrap__boxes">
                    <div className="__enter__promoCode">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="email">My Promo Codes</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter promo code"
                        />
                      </div>
                      <div className="__submit__wrap">
                        <button className="__default">Add</button>
                      </div>
                    </div>
                  </div>
                  <div className="m_t_30"></div>
                  <div className="__wrap__boxes p_0 __promo__code__list">
                    <div className="__faq__help">
                      <a href="">
                        <img src="/assets/images/faq-help.svg" />
                        <span>FAQ</span>
                      </a>
                    </div>
                    <div className="__tabs">
                      <ul>
                        <li className="active">Available</li>
                        <li>Used</li>
                        <li>Expired</li>
                      </ul>
                    </div>
                    <div className="__list__info">
                      <div className="__empty__list">
                        <p>You have no available promo codes</p>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="__invite__a__friend">
                  <div className="__wrap__boxes">
                    <div className="__channel">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="email">Invite Friends via Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter an email address"
                        />
                      </div>
                      <div className="__submit__wrap">
                        <button className="__default">Invite</button>
                      </div>
                    </div>
                    <div className="__channel">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="email">Share Your Invite Link</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Https://www..........."
                        />
                      </div>
                      <div className="__submit__wrap">
                        <button className="__default">Copy</button>
                      </div>
                    </div>
                    <ul className="__social__links">
                      <li>
                        <a href="">
                          <img src="/assets/images/facebook-icon-2.svg" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <img src="/assets/images/twitter-icon-2.svg" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <img src="/assets/images/linkin-icon-2.svg" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="m_t_30"></div>
                  <h6 className="__inner__title">Your Invitations</h6>
                  <div className="__wrap__boxes">
                    <div className="__lr__lists">
                      <div className="__list__">
                        <div className="__l">Friend registered</div>
                        <div className="__r">0</div>
                      </div>
                      <div className="__list__">
                        <div className="__l">Booking Completed</div>
                        <div className="__r">0</div>
                      </div>
                      <div className="__list__ __list__total">
                        <div className="__l">Total amount earned</div>
                        <div className="__r">(BDT0.00)</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="__wrap__boxes">
                    <div className="__search__trip">
                      <div className="__trip__select">
                        <div className="form-group select_t_default">
                          <label htmlFor="select_t_default">Trip Type</label>
                          <div className="custom_select_wrap">
                            <select
                              className="form-control"
                              id="select_t_default"
                            >
                              <option></option>
                              <option>3 Days</option>
                              <option>7 Days</option>
                            </select>
                            <span className="custom_arrow"></span>
                          </div>
                        </div>
                      </div>
                      <div className="__trip__select">
                        <div className="form-group select_t_default">
                          <label htmlFor="select_t_default">Trip Type</label>
                          <div className="custom_select_wrap">
                            <select
                              className="form-control"
                              id="select_t_default"
                            >
                              <option></option>
                              <option>3 Days</option>
                              <option>7 Days</option>
                            </select>
                            <span className="custom_arrow"></span>
                          </div>
                        </div>
                      </div>
                      <div className="__trip__select">
                        <div className="form-group select_t_default">
                          <label htmlFor="select_t_default">Trip Type</label>
                          <div className="custom_select_wrap">
                            <select
                              className="form-control"
                              id="select_t_default"
                            >
                              <option></option>
                              <option>3 Days</option>
                              <option>7 Days</option>
                            </select>
                            <span className="custom_arrow"></span>
                          </div>
                        </div>
                      </div>
                      <div className="__submit__wrap">
                        <button className="__default">Search</button>
                      </div>
                    </div>
                    <div className="__list__info">
                      <div className="__empty__list">
                        <p>No Records found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
}

export default MyAccount;
