import React from 'react'
import Footer from '../components/footer'
import Navigation from '../components/navigation'

const TermsOfUse = () => {
    const styleOfTerms = {
        fontSize: "13px",
        fontFamily: '"Times New Roman","serif"',
        marginBottom: "25px"
    }
    const styleOfTermsTitle= {
        fontSize: "21px",
        fontFamily: '"Helvetica","sans-serif"',
    }
    return (
        <section className="container">
            <Navigation />
            <div>
                <h3>Terms & Conditions</h3>
                <h4 style={styleOfTermsTitle}>Introduction</h4>
                <p style={styleOfTerms}>
                    Please carefully read the terms and conditions before using any of the services provided by BuyAirTicket.com. By using any materials, information, products or services of BuyAirTicket.com, you agree to these terms and conditions.
                    Terms & conditions (T&C) that follow in this article, as may be revised from time to time, are applicable to all our services available online or offline. These terms and conditions are applicable to the services we provide for personal, non-commercial use of our services. These T&C will not be applicable to our relationship with other commercial service providers (B2B) that we have an affiliation with.
                </p>
                <h4 style={styleOfTermsTitle}>General Disclaimer</h4>
                <p style={styleOfTerms}>
                    BuyAirTicket.com does not own any travel products or services. We are simply an intermediary which connects the customers with the travel service providers like flights, commute, accommodation etc. The travel products on BuyAirTicket.com are sold by third party travel providers. The travel providers are responsible for their products and services, and you must understand and agree to the terms and conditions of the third-party travel providers before booking or purchasing their products through BuyAirTicket.com. If something should go wrong during booking or travel period, BuyAirTicket.com cannot be held accountable, and you must resolve your dispute directly with the travel provider in question.
                </p>
                <h4 style={styleOfTermsTitle}>Cancellation, Refunds, and Reissues</h4>
                <p style={styleOfTerms}>
                    BuyAirTicket.com will follow supplier’s (Airline/ Hotel/ Others) policy for cancellation/ reissue/ refund. BuyAirTicket.com may charge customer with a fee for any kind of cancellation/ reissue/ other changes. For Domestic/ International flights, BuyAirTicket.com will charge BDT per person as standard cancellation fee. In case of date change/ reissue, BuyAirTicket.com will charge BDT per person for domestic/ international flights. In case of refund/ reissue, customer must inform BuyAirTicket.com 48 hours prior to travel date.
                </p>
                <h4 style={styleOfTermsTitle}>CHANGES TO FLIGHTS ALREADY PURCHASED</h4>
                <p style={styleOfTerms}>Any and all changes made to the itinerary are restricted and are subject to airline fare rules, whichever is more restrictive; most of our tickets, hotels, cars, packages and cruises do not allow any date or name changes after the booking is completed. BuyAirTicket.com does not guarantee, and shall not be responsible for, any bookings or reservations made or confirmed to you in the event that the original itinerary has been changed by the supplier pursuant to customer's request or supplier's schedule changes.</p>
                <h4 style={styleOfTermsTitle}>CANCEL AND EXCHANGE</h4>
                <p style={styleOfTerms}>Most of our airline tickets are 100% non-refundable. In certain cases, where the airline may allow cancellations, a credit may be valid towards a future ticket purchase by the same traveler on the same airline. Usually, the credit issued by the airline supplier has a specific expiration date, after which it cannot be used. We encourage you to discuss additional restrictions attached to your credit with a customer service agent. All such bookings where the cancellation may be permitted must be cancelled prior to the scheduled departure time of the first flight segment by calling our customer service center. We do not guarantee any cancellation. When you are ready to make your new booking and wish to use your airline credit, you will be required to the pay fare difference (if any), applicable airline penalties and any applicable BuyAirTicket.com post-ticketing fees. All such changes are governed by each airline's fare rules, policies and procedures, which are not under our control.</p>
                <h4 style={styleOfTermsTitle}>Promotional Codes</h4>
                <p style={styleOfTerms}>
                    BuyAirTicket.com may issue certain promo codes which are generally valid for online travel reservations and bookings, though some specific BuyAirTicket.com promo codes may only be used over the phone through our customer service center.
                    <br />
                    <br />
                    We invite you to sign up for our newsletter to receive promo codes by email.
                    <br />
                    <br />
                    The maximum discount from all BuyAirTicket.com promo codes is the value of our service fees and/or "Traveler Assist" fees (up to 70%).
                    <br />
                    <br />
                    BuyAirTicket.com promo codes are non-transferable, cannot be sold or bartered and hold no cash value.
                    <br />
                    <br />
                    To receive the value of the discount, a valid promo code must be entered in the promo code link on payment page. If the code is not entered the discount cannot be redeemed and has no value. Due to technical problems, if the code is not accepted or a coupon link is not present, you have the right to not purchase the product or service, but in no circumstances will the credit be applied after purchase is made.
                    <br />
                    <br />
                    BuyAirTicket.com promo codes offers may be revised or withdrawn any time without notice, even if other websites are displaying the same offers.
                    <br />
                    <br />
                    For all technical errors there is no recourse except you have the right to not make the purchase.
                    If the offer is withdrawn, the promo code becomes invalid and the Site and system will not accept the promo code when entered. This is final and you have the right at that point to continue with original price or not continue with your purchase.
                    <br />
                    <br />
                    The final price displayed (with or without promo code) will be the amount billed/charged and there will be no credits/discounts applied after purchase for absolutely any reason.
                    BuyAirTicket.com promo codes may not be combined with another offer.
                    <br />
                    <br />
                    We reserve the right to decline any transaction that may have had an error in promo code value even after the booking is created and booking receipt is issued.
                </p>
            </div>
            <Footer />
        </section>
    )
}

export default TermsOfUse
