import { createContext, useContext, useEffect, useState } from "react";

const Context = createContext();

const ContextProvider = ({children}) =>{
    const [comment, setComment] = useState({});
    const [review, setReview] = useState(0);
    const [avgReview, setAvgReview] = useState(0);
    const [data, setData] = useState([]);
    const [googleUser, setGoogleUser] = useState({});
    const [isLogin, setIsLogin] = useState(false);
    const baseUrl = 'https://admin.buyairticket.com.bd'
    const fetchUrl = ()=>{
    fetch(`${baseUrl}/api/blog/getblogs/1/20`)
        .then(res => res.json())
        .then(data => {
            setData(data.DataList);
        })
        
    }
    useEffect(()=>{
        fetchUrl()
    },[])
    // console.log(data);
    return(
        <Context.Provider
            value={{
                data,
                comment,
                review,
                avgReview,
                googleUser,
                isLogin,
                setComment,
                setReview,
                setAvgReview,
                setData,
                setGoogleUser,
                setIsLogin,
                
            }}
        >
            {children}
        </Context.Provider>
    )
}

export const useGlobalContext = () =>{
    return useContext(Context);
}
export { Context, ContextProvider};