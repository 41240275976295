import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }}
      onClick={onClick}
    >
      <img src="/assets/images/next-a.svg" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }}
      onClick={onClick}
    >
      <img src="/assets/images/after-a.svg" />
    </div>
  );
}

const Column3SliderShop=()=>{
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const titleHeight ={
      minHeight: "64px"
    }
    return (
      <Slider {...settings}>
        <div>
          <div className="__vh__card __vh__card__black">
            <div className="__card__imag">
              <img src="/assets/images/emirates-red-cap.jpg" />
            </div>
            <div className="__card__info">
              <h4 style={{minHeight:"64px"}} className="__info__title">
                Emirates microfiber cap, red color
              </h4>
              {/* <div className="__info__card__showprice">
                <button>Show Price</button>
              </div> */}
              {/* <p className="__info__desc">
                    Tour the World from Your Couch with #ExperienceLive!
                  </p> */}
              <div className="__info__cta">
                <a href="http://eshoppingmall.xyz" target="_blank">Shop Now</a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="__vh__card __vh__card__black">
            <div className=" __card__imag">
              <img src="/assets/images/troli-2.png" />
            </div>
            <div className="__card__info">
              <h4  style={{minHeight:"64px"}} className="__info__title">
                Carry on Baggage
              </h4>
              {/* <div className="__info__card__showprice">
                <button>Show Price</button>
              </div> */}
              <div className="__info__cta">
                <a href="http://eshoppingmall.xyz" target="_blank">Shop Now</a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="__vh__card __vh__card__black">
            <div className="__card__imag">
              <img src="/assets/images/troli-1.png" />
            </div>
            <div className="__card__info">
              <h4 style={{minHeight:"64px"}} className="__info__title">
              Checked Baggage
              </h4>
              {/* <div className="__info__card__showprice">
                <button>Show Price</button>
              </div> */}
              {/* <p className="__info__desc">
                    Tour the World from Your Couch with #ExperienceLive!
                  </p> */}
              <div className="__info__cta">
                <a href="http://eshoppingmall.xyz" target="_blank">Shop Now</a>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    );
  }

export default Column3SliderShop;
