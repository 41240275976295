import React, { useState, useEffect } from 'react'
import { Context, useGlobalContext } from '../../context/Context';
import { useForm } from 'react-hook-form';
import { HashLink as Link } from 'react-router-hash-link';

const PersonalDetails = () => {
    const {googleUser} = useGlobalContext(Context);
    const baseURL = `https://admin.buyairticket.com.bd`;
    const currentUser = JSON.parse(sessionStorage.getItem("user"));
    const isEditable = localStorage.getItem("notEditable")
    const currentUserEmail = currentUser?.Email;
    const title = currentUser?.Title;
    const name = currentUser?.name;
    const fname = currentUser?.FirstName;
    const lname = currentUser?.LastName;
    const username = `${title} ${fname} ${lname}`
    const phoneNumber = currentUser?.Phone;

    // update user
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [isEdit, setIsEdit] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [success, setSuccess] = useState(false); 
    const handleEditUser = ()=>{
        setIsEdit(true)
        fetch(`${baseURL}/api/user/getuserbyemail/${currentUserEmail}`)
        .then(res => res.json())
        .then( res =>{
            setUserInfo(res);
        })
    }
    const [file, setFile] = useState("")
    // base 64 with url parameter
      
    function ViewImage(event){
      function getBase64(file) {
       return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result);
         reader.onerror = error => reject(error);
       });
     }
     var file = event.target.files[0];
     console.log(file);
     getBase64(file)
     .then(data => {
       const base64Data = data.replace(/^data:image\/\w+;base64,/, "");
       console.log("base64Data");
       console.log(base64Data);
       setFile(base64Data);
      //  sessionStorage.setItem('profileImg', base64Data);
      });
     }
    
    useEffect(() => {
      console.log(userInfo);
    }, [userInfo, setUserInfo]);
    const handleUser = (fieldName, event) => {
      if(fieldName === "Title") {
        setUserInfo({...userInfo, "Title": event.currentTarget.value});
      } else if(fieldName === "FirstName") {
        setUserInfo({...userInfo, "FirstName": event.currentTarget.value});
      } else if(fieldName === "LastName") {
        setUserInfo({...userInfo, "LastName": event.currentTarget.value});
      } else if(fieldName === "Phone") {
        setUserInfo({...userInfo, "Phone": event.currentTarget.value});
      }
    }
    // phone number validation
    function phoneNumberValidation(){
      var txtPhone  = document.getElementById("phone").value;
      var mobileno = /^((\\+91-?)|0)?[0-9]{10}$/;
      if ((txtPhone.match(mobileno))) {
        document.getElementById("demo").innerHTML = "";
    }
    else {
        document.getElementById("demo").innerHTML = "Not Valid";
    }

  
    }
    


    const onSubmit = data => {
        const password = "1234";
        const {Title,firstName,lastName,phone,profileImage} = data;
        const userData = {
         title: userInfo.Title,
         firstName: userInfo.FirstName, 
         lastName: userInfo.LastName,
         email: currentUserEmail,
         password,
         phone: userInfo.Phone,
         profileImage: file ,
         image: null,
        };
        console.log(data);
        console.log(userData);
        fetch(`${baseURL}/api/user/updateprofile`,{
          method:"POST",
          headers: { 
            "Content-Type": "application/json",
            'Accept': 'application/json, text/plain, */*',
          },
          body: JSON.stringify(userData)
        })
        .then(res => res.json())
        .then(success =>{
          if(success){
              setSuccess(true);
              alert("profile updated!")
              sessionStorage.setItem("user", JSON.stringify(userData))
          }
        })
    };
    const loginData = {
      email: currentUserEmail,
      password: sessionStorage.getItem("pass")
    }
    success &&  fetch(`https://admin.buyairticket.com.bd/api/user/signin`,{
      method:'POST',
      headers: { 
        "Content-Type": "application/json",
        'Accept': 'application/json, text/plain, */*',
      },
      body: JSON.stringify(loginData)
    })
    .then(res => res.json())
    .then(success =>{
      if(success){
      console.log(success)
      sessionStorage.setItem("user", JSON.stringify(success))
      window.location.reload();
      }
    })
    return (
        <>
            <div className="__wrap__boxes" id="personalDetails">
                <div className="__p__information">
                <div className="__title__withicon">
                    <div className="__title__icon">
                      {
                      isEditable ? <a>
                        <img src="/assets/images/edit-icon.svg" />
                      </a> : <a onClick={handleEditUser}>
                        <img src="/assets/images/edit-icon.svg" />
                      </a>
                      }
                    </div>
                    <h3 className="__title">Personal Information</h3>
                </div>
                {isEdit ? (
                  <div className="row">
                  <div className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo.Title}
                          onChange={(e)=>{handleUser('Title', e)}}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="firstName">First Name</label>
                        <input
                          type="text"
                          name="firstName"
                          className="form-control"
                          id="firstName"
                          value={userInfo.FirstName}
                          // value={manager}
                          onChange={(e)=>{handleUser('FirstName', e)}}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={userInfo.LastName}
                          onChange={(e)=>{handleUser('LastName', e)}}
                        />
                       </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="phone">Mobile Number</label>
                        <input
                          type="tel"
                          name="phone"
                          className="form-control"
                          id="phone"
                          value={userInfo.Phone}
                          onChange={(e)=>{handleUser('Phone', e)}}
                          onBlur={phoneNumberValidation}
                        />
                        <small style={{color: 'red'}} id="demo"></small>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="profileImage">Profile Image</label>
                        <input
                          type="file"
                          name="profileImage"
                          className="form-control"
                          id="profileImage"
                          onChange={ViewImage}
                        />
          
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="__btn__wrap m_t_20">
                        <button onClick={()=>{onSubmit(userInfo)}} type="submit" className="__save" >Submit</button>
                      </div>
                    </div>
                  </div>
                ):
                (<div className="__info__lists">
                    <div className="__info__list">
                        <div className="__info__list__label">Name :</div>
                        { name? 
                            <div className="__info__list__option">{ name} </div>:
                            <div className="__info__list__option">{ username} </div>
                        }
                        
                    </div>
                    <div className="__info__list">
                    <div className="__info__list__label">Email Id :</div>
                    <div className="__info__list__option">
                        {currentUserEmail }
                    </div>
                    </div>
                    <div className="__info__list">
                    <div className="__info__list__label">Phone :</div>
                    <div className="__info__list__option">{phoneNumber}</div>
                    </div>

                    {/* <div className="__info__list">
                    <div className="__info__list__label">Address :</div>
                    <div className="__info__list__option">
                        House 607 Road 10 Adabor Mohammadpur Dhaka
                    </div>
                    </div> */}

                    {/* <div className="__info__list">
                    <div className="__info__list__label">City :</div>
                    <div className="__info__list__option">Dhaka</div>
                    </div> */}

                    {/* <div className="__info__list">
                    <div className="__info__list__label">Country :</div>
                    <div className="__info__list__option">Bangladesh</div>
                    </div> */}

                    {/* <div className="__info__list">
                    <div className="__info__list__label">Zip :</div>
                    <div className="__info__list__option">1203</div>
                    </div> */}
                </div>)
                }
                </div>
            </div>
        </>
    )
}

export default PersonalDetails
