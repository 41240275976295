import React, { Component } from "react";
import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import SmallNav from "../../components/smallNav";

class ManageBooking extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navigation />
        <SmallNav top={true} bottom={false} />
        <div className="__page__wrap spacing_l">
          <div className="container container-lg container-xl container-xxl">
            <div className="row">
              <div className="col-12">
                <div className="__wrap__boxes __manage__booking">
                  <div className="__tab__left">
                    <ul>
                      <li className="active">Flight</li>
                      <li>Hotel</li>
                    </ul>
                  </div>
                  <div className="__tab__info__right">
                    <div className="info__option__lists">
                      <div className="__option">
                        <img src="/assets/images/printer-icon.svg" />
                        <p>Print your E-Ticket</p>
                      </div>
                      <div className="__option">
                        <img src="/assets/images/printer-icon.svg" />
                        <p>Modification</p>
                      </div>
                      <div className="__option">
                        <img src="/assets/images/printer-icon.svg" />
                        <p>Cancellations</p>
                      </div>
                      <div className="__option">
                        <img src="/assets/images/printer-icon.svg" />
                        <p>Check Refund Status</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default ManageBooking;
