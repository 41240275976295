import React from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

const schema = yup.object().shape({
    newPassword: yup
        .string()
        .required('Please Enter your password')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    confirm_password: yup
        .string()
        .required('Confirm Password is required')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .oneOf([yup.ref('newPassword'), null], 'Confirm Password does not match'),
});

const ChangePassword = () => {
    const baseURL = `https://admin.buyairticket.com.bd`;
    const currentUser = JSON.parse(sessionStorage.getItem("user"));
    const isEditable = localStorage.getItem("notEditable")
    console.log(currentUser);
    const currentUserEmail = currentUser?.Email;
    const history = useHistory();
    const { register, formState: { errors }, handleSubmit } = useForm({
        mode: "onBlur",
        resolver: yupResolver(schema),
    });
    const onSubmit = data =>{
        const {oldPassword, newPassword, confirm_password} = data;
        const newUpdatedPassword = {
            email: currentUserEmail,
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirm_password,
        }
        console.log(newUpdatedPassword);

        try{
            fetch(`${baseURL}/api/user/changepassword`,{
                method: 'POST',
                headers: { 
                    "Content-Type": "application/json",
                    'Accept': 'application/json, text/plain, */*',
                  },
                body: JSON.stringify(newUpdatedPassword)
            })
            .then(res => res.json())
            .then(success =>{
                if(success === 5){
                    alert("Old password and new password are same");
                }
                if(success === 4){
                    alert("Current password is wrong");
                }
                if(success === 3){
                    alert("Email address wrong");
                }
                if(success ===2){
                    alert("does't match password")
                }
                if(success === 1){
                    alert("your password is updated now!");
                }
            })
        }catch(err){
            console.log(err);
        }
    }
    return (
        <> 
        {
            isEditable? "":
        
        <div className="__wrap__boxes" id="changePassword">
            <div className="__change__password">
            <div className="__title__withicon">
            <div className="__title__icon">
            </div>
            <h3 className="__title">Change Password</h3>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="__info__lists __info__lists__wInput">
                <div className="__info__list">
                    <div className="__info__list__label">
                    Current password : * :
                    </div>
                    <div className="__info__list__option">
                    <div className="form-group input_t_text">
                    <input
                        name="oldPassword"
                        type="password"
                        className="form-control"
                        id="oldPassword"
                        placeholder="Current Password"
                        {...register('oldPassword')}
                        error = {!!errors.oldPassword}
                    />
                    <small style={{color: 'red'}}>{errors?.oldPassword?.message}</small>
                    </div>
                    </div>
                </div>
                <div className="__info__list">
                    <div className="__info__list__label">
                    New password : * :
                    </div>
                    <div className="__info__list__option">
                    <div className="form-group input_t_text">
                    <input
                        name="newPassword"
                        type="password"
                        className="form-control"
                        id="newPassword"
                        placeholder="New Password"
                        {...register('newPassword')}
                        error = {!!errors.newPassword}
                    />
                    <small style={{color: 'red'}}>{errors?.newPassword?.message}</small>
                    </div>
                    </div>
                </div>
                <div className="__info__list">
                    <div className="__info__list__label">
                    Confirm password : *
                    </div>
                    <div className="__info__list__option">
                    <div className="form-group input_t_text">
                    <input
                        name="confirm_password"
                        type="password"
                        className="form-control"
                        id="confirm_password"
                        placeholder="Confirm Password"
                        {...register('confirm_password')}
                        error = {!!errors.confirm_password}
                    />
                    <small style={{color: 'red'}}>{errors?.confirm_password?.message}</small>
                    </div>
                    </div>
                </div>
                <div className="__info__list">
                    <div className="__info__list__label"></div>
                    <div className="__info__list__option">
                    <div className="__submit__cta rightEl">
                        <button className="__save">Save</button>
                        <button className="__cancel">Cancel</button>
                    </div>
                    </div>
                </div>
                </div>
            </form>
            </div>
        </div>
        }
        </>
    )
}

export default ChangePassword
