import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import firebaseConfig from './firebase/firebase.config';
import { Context, useGlobalContext } from '../../context/Context';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
const schema = yup.object().shape({
  email: yup
      .string()
      .email("Email should have correct format")
      .required("Email is a require field"),
  password: yup
      .string()
      .required('Please Enter your password'),
});

const CustomeLogin = () => {
  const baseUrl = `https://admin.buyairticket.com.bd`;
  const {setGoogleUser, setIsLogin, isLogin} = useGlobalContext(Context);
  const [error,setError] = useState(false);
  const [status, setStatus] = useState(null);
  // find user start for only show error message
  const [email, setEmail] = useState('');
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }
    const fbProvider = new firebase.auth.FacebookAuthProvider();
    const handleGoogleSignIn = () => {
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider).then(function (result) {
        const { displayName, email,photoURL } = result.user;
        const Fname = displayName.split(" ")
        const googleUser = { name: displayName, fName: Fname[0], Email:email, photo: photoURL }
        setGoogleUser(googleUser);
        setIsLogin(true);
        sessionStorage.setItem('user', JSON.stringify(googleUser));
        // console.log(result.user);
        // console.log(googleUser);
        localStorage.setItem("isLogin", true)
        localStorage.setItem("notEditable", true)
        history.push("/")
      }).catch(function (error) {
        const errorMessage = error.message;
        console.log(errorMessage);
      });
    }
    const handleFacebookSignIn =()=>{
     
    firebase
    .auth()
    .signInWithPopup(fbProvider)
    .then((result) => {
      const { displayName, email, phoneNumber, photoURL } = result.user;
      const Fname = displayName.split(" ")
      const signInUser = {
        isSignIn: true,
        name: displayName,
        fName: Fname[0],
        email: email,
        phone: phoneNumber,
        photo: photoURL,
      }
      setIsLogin(true);
      localStorage.setItem("isLogin", true)
      localStorage.setItem("notEditable", true)
      sessionStorage.setItem('user', JSON.stringify(signInUser));
      history.push("/")
    })
    .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        console.log(errorCode)
        var errorMessage = error.message;
        console.log(errorMessage)
        
      });
    }
    // const storeAuthToken = () => {
    //   firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
    //     .then(function (idToken) {
    //       sessionStorage.setItem('token', idToken);
    //       history.replace(from);
    //       // history.push("/")
    //     }).catch(function (error) {
    //       // Handle error
    //     });
    // }
    const history = useHistory();
    const { register, formState: { errors }, handleSubmit } = useForm({
      mode: "onBlur",
      resolver: yupResolver(schema),
  });
    const onSubmit = data => {
        const {password, email} = data;
        const loginData = {
            email,
            password
        }
        setEmail(data?.email);
        // userInformation();
        try{
          fetch(`https://admin.buyairticket.com.bd/api/user/signin`,{
            method:'POST',
            headers: { 
              "Content-Type": "application/json",
              'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify(loginData)
          })
          .then(res => res.json())
          .then(success =>{
            console.log(success)
            sessionStorage.setItem("user", JSON.stringify(success))
            sessionStorage.setItem("pass", password);
            localStorage.setItem("isLogin", true)
            setIsLogin(true);
            setError(false)
            history.replace(from);
          }).catch((err)=>{
            setError(true)
            console.log(err);
          })
          
        }catch(err){
          console.log("err");
        }
       
    }
    console.log(error);
    return (
        <>
        <div className="__login">
          <div className="__login__content">
            <div className="container container-xl container-xxl">
            
              <div className="row">
                <div className="col-md-5 __order__2">
                  <div className="__login__inputs">
                    <div className="__social__login">
                      <button className="__google" onClick={handleGoogleSignIn}>GOOGLE</button>
                      <button className="__facebook" onClick={handleFacebookSignIn}>FACEBOOK</button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="inputs">
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="password"
                          placeholder="example.test@gmail.com"
                           {...register("email")}
                           error = {!!errors.email}
                          />
                          <small style={{color: 'red'}}>{errors?.email?.message}</small>
                      </div>
                      <div className="form-group input_t_textWLabel">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          name="password"
                          placeholder="Password"
                          {...register("password")}
                          error = {!!errors.password}
                        />
                        <small style={{color: 'red'}}>{errors?.password?.message}</small>
                      </div>
                      <p className="__f__password">
                        <Link to="/signup">Create account</Link>
                        <Link to="/forgot-password" style={{marginLeft:'10px'}}>Forgot Password?</Link>
                      </p>
                      <div className="CTA__wrap">
                        <button type="submit" className="__submit" >Submit</button>
                      </div>
                      { 
                        error &&<strong style={{color: 'white'}}>your email or password is wrong</strong>
                      }
                      {/* { 
                        status===null &&<strong style={{color: 'white'}}>Email is not registered!</strong>
                      } */}
                    </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5 __order__1">
                  <div className="__login__img">
                    <img src="/assets/images/login.PNG" alt="" />
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </>
    )
}

export default CustomeLogin
