
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Context, useGlobalContext } from '../../context/Context';
import { useForm } from "react-hook-form";
import './CustomeComment.css'
const Comments = ({blogId}) => {
  const { register, formState: { errors }, handleSubmit } = useForm();
    const {review, setReview, setAvgReview} = useGlobalContext(Context);
    const [clickMore, setClickMore] = useState(false);
    // console.log(blogId);
    const baseUrl = 'https://admin.buyairticket.com.bd'
    const [data, setData] = useState([]);
    const [totalComment, setTotalComment] = useState()
    const fetchUrl = ()=>{
      blogId && fetch(`${baseUrl}/api/blog/getblogdetailsbyid/${blogId}`)
      .then(res => res.json())
      .then(blogData => {
        const comment = blogData.CommentList
        setTotalComment(comment.length);
        // // console.log(totalComment);
        // console.log(comment);
        setData(comment);
      })
    }
    setReview(data.length);
    const reviewsNumber = data.reduce((totalReviews, review) => totalReviews + review.Rating, 0)
    const avgReviews = reviewsNumber / review;
    console.log(reviewsNumber, review);
    setAvgReview(avgReviews);
    // console.log(avgReviews);
    useEffect(()=>{
      fetchUrl()
    },[blogId, totalComment])

    // remove
    const removeComment=(Id)=>{
      fetch(`https://admin.buyairticket.com.bd/api/comment/deletecomment/${Id}`,{
        method: 'DELETE',
      })
      .then(() =>{
        const storComment = data.filter( bData => bData.Id === Id);
        // console.log(storComment);
        setData(storComment)
      })
    }
    // update
    const currentUser = JSON.parse(sessionStorage.getItem("user"));
    const currentUserEmail = currentUser?.Email;
    const userPhoto = currentUser?.ProfileImage;
    // console.log(currentUserEmail);
    
    return (
        <section className="__comments__view">
        <div className="__comments__view__heading">
          <h3>Recent Commeents</h3>
        </div>
        { data.map((blog, index) =>
        
        <div key={index} className="__comment__lists">
          <div className="__list">
            <div className="__list__img">
            {
              userPhoto ? <img src={`${baseUrl}/img/profile/${blog?.ProfileImage}`} alt="Profile" style={{height: '40px', width:'40px', borderRadius:'50%'}}/>:
              <img src="/assets/images/user-icon.svg" />
            }
             
              
              {/* <span className="__list__image">{blog?.Name.slice(4,5)}</span> */}
             
              {/* <img src="/assets/images/ellipse.png" /> */}
            </div>
           
            <div className="__list__info">
              <div className="__list__info__heading">
                <div className="__list__info__rating">
                  <div className="__rating__title">{blog.Name}</div>
                  <ul className="__review__star">
                    {/* {blog.Rating} */}
                    {
                      blog.Rating >= 1 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
                    }
                    {/* {
                      blog.Rating === 1.5 &&<li className="__info__review__starHalf"></li>
                    } */}
                    {
                      blog.Rating >= 2 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
                    }
                    {/* {
                      blog.Rating === 2.5 ?<li className="__info__review__starHalf"></li>: ""
                    } */}
                    {
                      blog.Rating >= 3 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
                    }
                    {/* {
                      blog.Rating === 3.5 &&<li className="__info__review__starHalf"></li>
                    } */}
                    {
                      blog.Rating >= 4 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
                    }
                    {/* {
                      blog.Rating === 4.5 &&<li className="__info__review__starHalf"></li>
                    } */}
                    {
                      blog.Rating >= 5 ?<li className="__info__review__starFull"></li>: <li className="__info__review__starNull"></li>
                    }
                  </ul>
                </div>
              </div>
              <div className="__list__info__desc mb-4">
                {
                  !clickMore &&  <div>{blog.Comments.slice(0,90)}
                  <span>{blog.Comments.slice(0,90).length === 90 ?<span onClick={()=> {setClickMore(true)}} style={{cursor:'pointer'}}>..More</span>:""}</span>
                 </div>
                }
                 {
                   clickMore &&<div> {blog.Comments}</div>
                 }
                  {/* <div className="edit__delete__btn">
                <span style={{marginLeft:'10px'}} onClick={()=>{removeComment(blog.CommentId)}}>delete</span>
              </div> */}
              </div>
            </div> 
          </div>
        </div>
        )}
      </section>
    )
}

export default Comments
