import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }}
      onClick={onClick}
    >
      <img src="/assets/images/next-a.svg" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }}
      onClick={onClick}
    >
      <img src="/assets/images/after-a.svg" />
    </div>
  );
}

class FlightSearchLanding extends Component {
  state = {};
  render() {
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 6,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            slidesPerRow: 1,
          },
        },
      ],
    };
    return (
      <React.Fragment>
        <Navigation />

        <div className="__page__wrap spacing_l">
          <div className="container container-lg container-xl container-xxl">
            <section className="__top__filters">
              <h4 className="text-center">
                Calenter Package place KORBE React developer
              </h4>
              <br />
              <div className="__sorting__options">
                <div className="form-group select_t_default">
                  <div className="custom_select_wrap arrow_small">
                    <select className="form-control" id="select_t_default">
                      <option>Stops</option>
                      <option>3 Days</option>
                      <option>7 Days</option>
                    </select>
                    <span
                      className="custom_arrow_small"
                      style={{
                        backgroundImage:
                          "url('/assets/images/bottom-arrowsmall.svg')",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></span>
                  </div>
                </div>
                <div className="form-group select_t_default">
                  <div className="custom_select_wrap arrow_small">
                    <select className="form-control" id="select_t_default">
                      <option>Airlines</option>
                      <option>3 Days</option>
                      <option>7 Days</option>
                    </select>
                    <span
                      className="custom_arrow_small"
                      style={{
                        backgroundImage:
                          "url('/assets/images/bottom-arrowsmall.svg')",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></span>
                  </div>
                </div>
                <div className="form-group select_t_default">
                  <div className="custom_select_wrap arrow_small">
                    <select className="form-control" id="select_t_default">
                      <option>Bags</option>
                      <option>3 Days</option>
                      <option>7 Days</option>
                    </select>
                    <span
                      className="custom_arrow_small"
                      style={{
                        backgroundImage:
                          "url('/assets/images/bottom-arrowsmall.svg')",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></span>
                  </div>
                </div>
                <div className="form-group select_t_default">
                  <div className="custom_select_wrap arrow_small">
                    <select className="form-control" id="select_t_default">
                      <option>Price</option>
                      <option>3 Days</option>
                      <option>7 Days</option>
                    </select>
                    <span
                      className="custom_arrow_small"
                      style={{
                        backgroundImage:
                          "url('/assets/images/bottom-arrowsmall.svg')",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></span>
                  </div>
                </div>
                <div className="form-group select_t_default">
                  <div className="custom_select_wrap arrow_small">
                    <select className="form-control" id="select_t_default">
                      <option>Connecting airports</option>
                      <option>3 Days</option>
                      <option>7 Days</option>
                    </select>
                    <span
                      className="custom_arrow_small"
                      style={{
                        backgroundImage:
                          "url('/assets/images/bottom-arrowsmall.svg')",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></span>
                  </div>
                </div>
                <div className="form-group select_t_default">
                  <div className="custom_select_wrap arrow_small">
                    <select className="form-control" id="select_t_default">
                      <option>Times</option>
                      <option>3 Days</option>
                      <option>7 Days</option>
                    </select>
                    <span
                      className="custom_arrow_small"
                      style={{
                        backgroundImage:
                          "url('/assets/images/bottom-arrowsmall.svg')",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></span>
                  </div>
                </div>
                <div className="form-group select_t_default">
                  <div className="custom_select_wrap arrow_small">
                    <select className="form-control" id="select_t_default">
                      <option>Duration</option>
                      <option>3 Days</option>
                      <option>7 Days</option>
                    </select>
                    <span
                      className="custom_arrow_small"
                      style={{
                        backgroundImage:
                          "url('/assets/images/bottom-arrowsmall.svg')",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></span>
                  </div>
                </div>
                <div className="form-group select_t_default">
                  <div className="custom_select_wrap arrow_small">
                    <select className="form-control" id="select_t_default">
                      <option>More</option>
                      <option>3 Days</option>
                      <option>7 Days</option>
                    </select>
                    <span
                      className="custom_arrow_small"
                      style={{
                        backgroundImage:
                          "url('/assets/images/bottom-arrowsmall.svg')",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></span>
                  </div>
                </div>
              </div>
            </section>

            <section className="__flight__sugg__lists __flight__sugg__slider">
              <Slider {...settings}>
                <div>
                  <a className="fcard" href="">
                    <div className="fcard__img">
                      <img src="/assets/images/emi.svg" alt="" />
                    </div>
                    <div className="fcard__info">
                      <p className="fcard__date">Sat, Jun 1</p>
                      <p className="fcard__price">৳ 878</p>
                    </div>
                  </a>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a className="fcard" href="">
                      <div className="fcard__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="fcard__info">
                        <p className="fcard__date">Sat, Jun 1</p>
                        <p className="fcard__price">৳ 878</p>
                      </div>
                    </a>
                  </div>
                </div>
              </Slider>
            </section>

            <section className="__round__trip m_t_40">
              <div className="__show__info">
                <div className="__show__info__wrap">
                  <div className="__show__l">
                    <div className="__show__t">
                      <div className="__show__t__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="__show__t__info">
                        <h6>Thu, Jul 20 1:40 AM – 2:25 AM</h6>
                        <p>Emirates</p>
                      </div>
                    </div>
                    <div className="__show__time">
                      <h6>24 hr 45 min</h6>
                      <p>DAC-JFK</p>
                    </div>
                    <div className="__show__stop">
                      <h6>1 stop</h6>
                      <p>4 hr DXB</p>
                    </div>
                  </div>
                  <div className="__show__l">
                    <div className="__show__t">
                      <div className="__show__t__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="__show__t__info">
                        <h6>Thu, Jul 20 1:40 AM – 2:25 AM</h6>
                        <p>Emirates</p>
                      </div>
                    </div>
                    <div className="__show__time">
                      <h6>24 hr 45 min</h6>
                      <p>DAC-JFK</p>
                    </div>
                    <div className="__show__stop">
                      <h6>1 stop</h6>
                      <p>4 hr DXB</p>
                    </div>
                  </div>
                </div>

                <div className="__show__r">
                  <div className="__show__rc">
                    <h6>BDT 10,845</h6>
                    <p>Round trip</p>
                  </div>
                  <div className="__show__rImg">
                    <img
                      className="__show__rImgTop"
                      src="/assets/images/plane-bw.svg"
                      alt=""
                    />
                    <img
                      className="__show__rImgBottom"
                      src="/assets/images/plane-bwb.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="__section__footer">
                <a href="">Baggage Allowance & Ticket Policy</a>
                <div>
                  <img src="/assets/images/d-icon.svg" />
                  Refundable
                </div>
                <a href="">Flight Details</a>
              </div>
            </section>

            <section className="__round__trip __oneway m_t_40">
              <div className="__show__info">
                <div className="__show__info__wrap">
                  <div className="__show__l">
                    <div className="__show__t">
                      <div className="__show__t__img">
                        <img src="/assets/images/emi.svg" alt="" />
                      </div>
                      <div className="__show__t__info">
                        <h6>Thu, Jul 20 1:40 AM – 2:25 AM</h6>
                        <p>Emirates</p>
                      </div>
                    </div>
                    <div className="__show__time">
                      <h6>24 hr 45 min</h6>
                      <p>DAC-JFK</p>
                    </div>
                    <div className="__show__stop">
                      <h6>1 stop</h6>
                      <p>4 hr DXB</p>
                    </div>
                  </div>
                </div>

                <div className="__show__r">
                  <div className="__show__rc">
                    <h6>BDT 10,845</h6>
                    <p>Oneway</p>
                  </div>
                  <div className="__show__rImg">
                    <img
                      className="__show__rImgTop"
                      src="/assets/images/plane-bw.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="__section__footer">
                <a href="">Baggage Allowance & Ticket Policy</a>
                <div>
                  <img src="/assets/images/d-icon.svg" />
                  Refundable
                </div>
                <a href="">Flight Details</a>
              </div>
            </section>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

export default FlightSearchLanding;
